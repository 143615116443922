import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { TranslateDay } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import { child, get, getDatabase, push, ref } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";
import { TeamTypeObj } from "@/app/core/util/helpers/TeamHelper";
import Storage from "@/app/core/util/Storage";
import handleChange from "@/app/core/util/helpers/HandleChange";

export default function TeamModal({ show, onHide, selected, teams }) {
  const [data, setData] = useState({});
  const [infos, setInfos] = useState({});
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = Storage.getUserData();
  const db = getDatabase(firebaseApp);

  useEffect(() => {
    if (selected !== undefined && selected.id !== undefined) {
      setData(selected);
      loadImages();
    } else {
        setData({});
    }
  }, [selected]);

  function handleOtherChange(evt, type) {
    setInfos(handleChange(infos, evt, type));
  }

  async function save() {
    setLoading(true);
  }

  function loadImages(){
    get(child(ref(db), `teams/${selected.id}/images`)).then((snapshot) => {
        let teams = [];
        let fields = [];
        if (snapshot.exists()) {
            const notYet = snapshot.val();
            for (let s in notYet) {
                teams.push({
                    ...notYet[s],
                    id: s,
                })
            }
            setImages(teams);
        }
    });
  }

  function processReviews(i){
        return <span>
            <i className={`fa fa-fw fa-star text-${i >= 1 ? "warning": "secondary"}`}></i>
            <i className={`fa fa-fw fa-star text-${i >= 2 ? "warning": "secondary"}`}></i>
            <i className={`fa fa-fw fa-star text-${i >= 3 ? "warning": "secondary"}`}></i>
            <i className={`fa fa-fw fa-star text-${i >= 4 ? "warning": "secondary"}`}></i>
            <i className={`fa fa-fw fa-star text-${i >= 5 ? "warning": "secondary"}`}></i>
        </span>
  }

function sendInvite(){
    if(infos.startTime === undefined || infos.endTime === undefined || infos.date === undefined){
        toast.error("Selecione a data e horário do convite");
    }else{
        var dateParts = infos.date.split("-");
        var dateObject = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
        const getWeekDay = dateObject.getDay();
        let checked = false;
        for(let i in data.schedule){
            const baseSchedule = data.schedule[i];
            let startTime = baseSchedule.startTime.split(":");
            let endTime = baseSchedule.endTime.split(":");
            let querystartTime = infos.startTime.split(":");
            let queryendTime = infos.endTime.split(":");

            if(baseSchedule.weekDay == getWeekDay &&
                startTime[0] == querystartTime[0] &&
                endTime[0] == queryendTime[0]){
                    checked = true;
            }
        }

        if(checked == false){
            toast.error("A data selecionada não se encaixa na agenda do time");
            return;
        }else{
            let myTeam = {};

            console.log("infosmações", userData, myTeam, teams);
            for(let s in teams){
                const team = teams[s];
                const eita = [];
                for(let z in team.users){
                    eita.push({
                        ...team.users[z],
                        id:z,
                    })
                }
                var res = eita.filter(x => x.userId == userData.id);
                if(res.length > 0){
                    myTeam = res[0];
                }
            }
            let invite = {
                title: `${myTeam.name} te desafiou para uma partida`,
                ...infos,
                status: 0,
                view: false,
            };

            console.log("infosmações", invite, userData, myTeam);

            if(data.type === TeamTypeObj.Principal){
                invite.principal = data.id;
                invite.visitor = myTeam.id;
            }else if(myTeam.type === TeamTypeObj.Principal){
                invite.principal = myTeam.id;
                invite.visitor = data.id;
            }else{
                invite.principal = myTeam.id;
                invite.visitor = data.id;
            }


            push(ref(db, 'invites'), {...invite});
            toast.success("Convite enviado com sucesso - Agora é só aguardar a confirmação");

            setTimeout(()=>{
                window.location = "#/";
            },500)
        }
    }
}

function handleSelectedDate(item, evt){
    if(evt.target.checked == true){
        const result = getNextDayOfTheWeek(item.weekDay);
        console.log("proximo dia", result);
        setInfos({...infos, startTime: item.startTime, endTime: item.endTime, date: `${result.getFullYear()}-${result.getMonth() + 1 < 10 ? `0${result.getMonth() + 1}`: result.getMonth()}-${result.getDate() < 10 ? `0${result.getDate()}`: result.getDate()}`})
    }
}

function getNextDayOfTheWeek(dayOfWeek, excludeToday = true, refDate = new Date()) {
    if (dayOfWeek < 0) return;
    refDate.setHours(0,0,0,0);
    refDate.setDate(refDate.getDate() + +!!excludeToday + 
                    (dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7);
    return refDate;
}

  return (
    <Modal
      title="Time"
      show={show}
      isLoading={loading}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <div className="row">
            <h3 className="col-2">
                <img src={data.logo && data.logo.url} className="img-avatar img-avatar-thumb img-avatar-rounded border-gray border-2x border"/>
            </h3>
            <div className="col-10 text-left">
                <h4 className="mb-0 font-w700">{data.name}</h4>
                <p>
                    {data.arena && data.arenaName}<br/>
                    {data.city} / {data.state}<br/>
                    {data && data.reviews && processReviews(data.reviews)} - {data && data.reviews && data.totalReviews}
                </p>
            </div>
            <div className="col-md-12">
                <table className="table table-borderless table-hover">
                    <tbody>
                        <tr>
                            <td>Nome campo/quadra:</td>
                            <td>{data && data.arenaName}</td>
                        </tr>
                        <tr>
                            <td>Endereço:</td>
                            <td>{data && data.street}</td>
                        </tr>
                        <tr>
                            <td>Numero:</td>
                            <td>{data && data.number}</td>
                        </tr>
                        <tr>
                            <td>CEP:</td>
                            <td>{data && data.cep}</td>
                        </tr>
                        <tr>
                            <td>Bairro:</td>
                            <td>{data && data.neighborhood}</td>
                        </tr>
                        <tr>
                            <td>Cidade:</td>
                            <td>{data && data.city}</td>
                        </tr>
                        <tr>
                            <td>Estado:</td>
                            <td>{data && data.state}</td>
                        </tr>
                        <tr>
                            <td>Referência:</td>
                            <td>{data && data.observation}</td>
                        </tr>
                        <tr>
                            <td>Tipo:</td>
                            <td>{data && data.fieldType && data.fieldType.name}</td>
                        </tr>
                    </tbody>
                </table>

                <div className="block block-rounded bg-gray-light">
                    <div className="block-header">
                        <div className="block-title">Dia e horário de jogos</div>
                    </div>
                            {data && data.schedule && data.schedule.map(item => (
                                <div className="ml-1 mr-1 mb-1 mt-1">
                                    <div className="js-task-status custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-warning custom-control-lg mb-2">
                                        <input type="checkbox" className="custom-control-input" id={`available-${item.id}`} name={`available-${item.id}`} onChange={(evt)=> handleSelectedDate(item, evt)}/>
                                        <label className="custom-control-label" for={`available-${item.id}`}>{TranslateDay(item.weekDay)} - {item.startTime} às {item.endTime}</label>
                                    </div>
                                </div>
                            ))}
                    {/* <div className="block-content">
                    </div> */}
                </div>
                <div className="block block-rounded bg-gray-light">
                    <div className="block-header">
                        <div className="block-title">Data escolhida</div>
                    </div>
                    <div className="row mb-1">
                            <div className="input-group col-4">
                                <input className="form-control" type="date" name="date" value={infos && infos.date} placeholder="Ou defina outra" onChange={evt => handleOtherChange(evt)}/>
                            </div>
                            <div className="input-group col-4">
                                <input className="form-control" type="time" name="startTime" value={infos && infos.startTime} placeholder="Ou defina outra" onChange={evt => handleOtherChange(evt)}/>
                            </div>
                            <div className="input-group col-4">
                                <input className="form-control" type="time" name="endTime" value={infos && infos.endTime} placeholder="Ou defina outra" onChange={evt => handleOtherChange(evt)}/>
                            </div>
                            <div className="input-group col-12 mt-2">
                                <button className="btn btn-block btn-hero-md btn-hero-warning mb-2" onClick={()=> sendInvite()}>
                                    Enviar Convite
                                </button>
                            </div>
                    </div>
                </div>
                <button className="btn btn-block btn-outline-warning mb-2 mt-2" onClick={()=> onHide()}>
                    Nova consulta
                </button>

                {images && images.length > 0 ? 
                    <h5>Fotos</h5>
                :""}
                <div className="row">

                    {images && images.map(item => (
                        <div class="block block-rounded block-fx-pop col-md-6">
                        <div class="block-content p-0 overflow-hidden">
                        <a
                            class="img-link img-link-zoom-in img-thumb img-lightbox img-fluid-100"
                            data-toggle="layout"
                            data-action="side_overlay_open"
                            target="_blank"
                            // onClick={() => openImage(data)}
                        >
                            <img class="img-fluid rounded-top" src={item && item.url} alt="" />
                        </a>
                        </div>
                        <div class="block-content block-content-full">
                        <div class="row">
                            <div class="col-8">
                            <h4 class="h6 mb-2">
                                {item && item.name}
                            </h4>
                            </div>
                        </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    </Modal>
  );
}
