import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import RecoverComponent from "./main";
import Storage from "@/app/core/util/Storage";
import Layout from "@/app/core/layouts/main";

const EmptyRouter = (props) =>
    Storage.isAuthenticated() ? (
        <Layout {...props}>{renderRoutes(props.route.routes, props)}</Layout>
    ) : (
        <Redirect to="/auth" />
    );

const ProfileRouter = () => ({
  path: "/profile",
  exact: true,
  component: EmptyRouter,
  routes: [
    {
      path: "/profile",
      component: RecoverComponent,
    },
  ],
});

export default ProfileRouter();
