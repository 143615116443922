import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import handleChange from "@/app/core/util/helpers/HandleChange";
import UserStep from "./UserStep";
import CheckPhoneStep from "./CheckPhoneStep";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { CheckUserData } from "@/app/core/util/helpers/UserHelper";
import AccountController from "@/app/core/base/controllers/UsersController";
import { useEffect } from "react";
import Storage from "@/app/core/util/Storage";
import { firebaseApp } from "@/app/core/base/ControllerBase";
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, push, ref, set, update } from "firebase/database";

export default function BaseUserRegister({goBack, teamId}) {
    const accountController = AccountController();
    const [step, setStep] = useState(1);
    const [data, setData] = useState({user:{}, sport: {}});
    const auth = getAuth();
    const db = getDatabase(firebaseApp);


    async function registerUser(){
      if(data.user.terms != true){
        toast.error(Message.Account.TermsNeeded);
      }else{
        const checkAccount = CheckUserData(data.user);
        
        if(checkAccount.error === true){
          console.log("Faltou mesmo", data, checkAccount);
          toast.error(checkAccount.message);
        }else{
          await createUserWithEmailAndPassword(auth, data.user.email, data.user.password)
          .then(async (userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log("Conta");
            await set(ref(db, `users/${user.uid}`), {
                ...data.user
            });
            if(teamId != null && teamId != undefined){
              await push(ref(db, `teams/${teamId}/users`), {
                  role: 2,
                  userId: user.uid
              });
            }
            setData({...data, user: {...data.user, id: user.uid}});
            setStep(step + 1);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            toast.error(errorMessage);
            // ..
          });
          // await accountController.Register(data.user, res => {
          //   console.log("usuário", res);
          //   setData({...data, user: res});
          // })
        }
      }
    }

    function returnStep(){
        setStep(step - 1);
    }

    function handleUserChange(evt, type){
        setData({...data, user: handleChange(data.user, evt, type)});
        console.log("Dados", data)
    }

    function returnChoices(){
        goBack()
    }

    function endRegister(){
      if(data && data.user.id != undefined){
        toast.success(Message.Account.SuccessfullyRegistred);
        Storage.setUserData(data.user);
        window.location = "#/";
      }
    }

    function preSave(){
      if(data.user != undefined && data.user.firstName !== undefined){
        Storage.setRegisterUserData(data);
      }
    }

    useEffect(()=>{
      preSave();
      console.log("mudou", data);
    },[data])

    useEffect(()=>{
      const base = Storage.getRegisterUserData();
      if(base != null && base.user && base.user.firstName != undefined){
        setData(base);
        if(base.user.id !== undefined){
          setStep(step + 1);
        }
      }
      console.log("usuário", base, data);
    },[])

  function changeStep() {
    switch (step) {
      case 1:
        return (
          <div>
            <UserStep
                nextStep={() => registerUser()}
                prevStep={() => returnChoices()}
                handleChange={(evt, type) => handleUserChange(evt, type)}
                data={data && data.user}
                base={true}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <CheckPhoneStep
                nextStep={() => endRegister()}
                prevStep={() => returnStep()}
                handleChange={(evt, type) => handleUserChange(evt, type)}
                data={data && data.user}
            />
          </div>
        );
      default:
        return (
          <div>
            <CheckPhoneStep
                nextStep={() => endRegister()}
                prevStep={() => returnStep()}
                handleChange={(evt, type) => handleUserChange(evt, type)}
                data={data && data.user}
            />
          </div>
        );
    }
  }

    return (
        <div className="col-sm-8 col-xl-8">
            {step && changeStep()}
        </div>
    )
}