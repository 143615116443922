export const DeleteConfirm =
  "Você tem certeza de que deseja excluir esse item?";

export const SavedMessage = "Salvo com sucesso";
export const WrongRole =
  "Você não tem permissão para prosseguir, entre em contato com o supervisor";

export const Message = {
  DeleteConfirm: "Você tem certeza de que deseja excluir esse item?",
  Confirm: "Você tem certeza de que deseja continuar?",
  DeleteSuccess: "Removido com sucesso",
  Saved: "Salvo com sucesso",
  Refused: "Recusado com sucesso",
  PendingData: "Preencha os dados para poder salvar",
  ServiceDataPending: "Não é possível aprovar um orçamento sem serviços",
  CanClose: "Você pode fechar essa janela",
  LowPrice:
    "O valor com a margem da contabilidade está abaixo dos honorários da Hasa",
  Preparation: {
    CustomerReturn: "Verifique o retorno do cliente para prosseguir",
    Consulting: "Resolva as consultas em aberto antes de enviar para conferência",
    InternalFeedback: "Verifique o feedback interno para prosseguir",
  },
  Account: {
    PasswordDiferent: "As senhas não batem",
    WeakPassword: "A senha é fraca",
    TermsNeeded: "Você precisa aceitar os termos de uso para continuar",
    AlreadyExist: "Já existe um usuário com esses dados",
    SuccessfullyRegistred: "Cadastrado com sucesso",
  }
};
