import React from "react";

export default function UserCard({user, edit, remove, open}){

    

    return (
        <div className="block block-rounded block-bordered" key={`item`}>
            <div className="block-header block-header-default ">
                <a className="block-title text-dark" href={`#/player/${user.id}`} target="_blank">
                    <img className="img-avatar img-avatar48 border-gray border-2x border" src={"assets/media/avatars/avatar16.jpg"} />
                    <span className="ml-3">
                        Fabio Rodrigues
                    </span>

                </a>
                {edit == true ? 
                    <div className="block-tools">
                        <button className="btn btn-sm btn-dual" onClick={()=> open(user.id)}>
                            <i className="fa fa-edit text-muted"/>
                        </button>
                        <button className="btn btn-sm btn-dual" onClick={()=> remove(user.id)}>
                            <i className="fa fa-trash text-muted"/>
                        </button>
                    </div>
                :""}
            </div>
        </div>
    )
}