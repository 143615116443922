import { firebaseApp } from "@/app/core/base/ControllerBase";
import { ContentType } from "@/app/core/util/helpers/ContentHelper";
import { child, get, getDatabase, ref } from "firebase/database";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";

export default function Post(props){
    const [data, setData] = useState({});
    const [query, setQuery] = useState("");
    const [otherNews, setOtherNews] = useState([]);
    const db = getDatabase(firebaseApp);

    useEffect(()=>{
        loadPresentation();
        loadOtherPosts();
    },[]);

    function loadPresentation() {
        let param = props.location.search;
        let teamId = "";
        let item = param.split("?")[1].split("&"),
        returnBool = false;

        for (var i = 0; i < item.length; i++) {
            let parr = item[i].split("=");
            if (parr[0] == "postId") {
                let itens = decodeURIComponent(parr[1]);
                teamId = itens
                returnBool = true;
            }
        }

        if (returnBool === true) {
            loadPost(teamId);
        }
      }

      function loadPost(id){
        get(child(ref(db), `news/${id}`)).then((snapshot) => {
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                console.log("Notícia", snapshot.val());
                setData(snapshot.val());
              }
          });
      }

      function loadOtherPosts(){
        get(child(ref(db), `news`)).then((snapshot) => {
            let sportsData = [];
            let res = [];
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    sportsData.push({
                        ...notYet[s],
                        id: s,
                    })
                }
                let count = 0;
                for(let s in sportsData){
                    const i = sportsData[s];
                    for(let c in i){
                        if(count <= 2){
                            count++;
                            res.push({...i[c], sportId: i.id, id: c});
                        }
                    }
                }
                console.log(res);
                setOtherNews(res)
              }
          });
      }

      function searchPost(e){
        if(query == ""){
            toast.error("O campo de pesquisa está vazio")
        }else{
            window.open(`#/search/?query=${query}`, "_blank");
        }
      }

      return (
        <div className="row mt-3">
            <div className="block block-rounded block-fx-pop col-md-8">
                <div className="block-content block-content-full bg-image p-7" style={{backgroundImage: `url('${data && data.image && data.image.url}')`, height: 360}}>
                </div>
                <div className="block-content block-content-full block-content-sm bg-body-light">
                    <div className="font-size-sm text-muted">{data && data.tags && data.tags.map(tag => <span className="text-warning mr-2">{tag} |</span>)}</div>
                    <div className="font-w600">{data && data.title}</div>
                </div>
                <div className="block-content block-content-full">
                    <p>{data && data.description}</p>
                </div>
                {data && data.sections && data.sections.map(item => {
                    if(item.type == ContentType.Text){
                        return <div className="block-content block-content-full">
                            <p>{item && item.description}</p>
                        </div>
                    }else if(item.type == ContentType.Image){
                        return <div className="block-content block-content-full">
                            <img src={item.url} style={{width: "100%"}}/>
                        <small>{item && item.description}</small>
                    </div>
                    }else if(item.type == ContentType.YoutubeVideo){
                        return <div className="block-content block-content-full">
                            <iframe width="560" height="315" src={item.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            <br/>
                            <small>{item && item.description}</small>
                    </div>
                    }
                })}
            </div>
            <div className="block block-transparent col-md-4">
                <div className="block-content">
                    <form onSubmit={() => searchPost()}>
                        <div className="form-group">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Pesquisar..." onChange={evt => setQuery(evt.target.value)} value={query}/>
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i className="fa fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="block-header">
                    <div className="block-title">
                        Veja também
                    </div>
                    <div className="block-tools">
                    </div>              
                </div>
                <div className="block-content">
                    {otherNews && otherNews.map(item => (
                        <a className="block block-rounded block-fx-pop" href={`#/post/?postId=${item.sportId}/${item.id}`} target="_blank">
                            <div className="block-content block-content-full bg-image p-7" style={{backgroundImage: `url('${item.image && item.image.url}')`}}>
                            </div>
                            <div className="block-content block-content-full block-content-sm bg-body-light">
                                <div className="font-size-sm text-muted">{item && item.tags && item.tags.map(tag => <span className="text-warning mr-2">{tag} |</span>)}</div>
                                <div className="font-w600">{item && item.title}</div>
                            </div>
                            <div className="block-content block-content-full">
                                <p>{item && item.description}</p>
                            </div>
                        </a>  
                    ))}
                </div>  
            </div>
        </div>
      )
}