import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { GenderType } from "@/app/core/util/helpers/UserHelper";
import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

export default function SportStep({sports, modules, handleChange, nextStep, prevStep, data}) {
    const [query, setQuery] = useState("");

    return (
        <div>
            <p className="text-uppercase font-w700 font-size-sm">
                Para começar, escolha um esporte
            </p>
            <div className="form-group">
              <span>Esporte</span>
              <Typeahead
                options={sports}
                labelKey={(option) => `${option.name}`}
                onSearch={(q) => setQuery(q)}
                id="advanced-filter-state"
                placeholder="Selecionar Esporte"
                onChange={(selected) => {
                  console.log("selecionado", selected)
                  if (selected[0] != undefined || selected[0] != null) {
                    handleChange({target: {value: selected[0].id, name: "sportId"}});
                  }
                }}
              />
            </div>
            <div className="form-group">
              <span>Tipo</span>
              <select className="form-control" name="genderId" value={data && data.genderId} onChange={evt => handleChange(evt, FieldTypeObj.Number)}>
                <option value={null}>Selecione</option>
                {GenderType.map(item => (
                    <option value={item.value}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <span>Modalidade</span>
              <Typeahead
                options={modules}
                labelKey={(option) => `${option.name}`}
                onSearch={(q) => setQuery(q)}
                id="advanced-filter-state"
                placeholder="Selecionar Modalidade"
                value={data && data.gameModuleId}
                onChange={(selected) => {
                  console.log("selecionado", selected)
                  if (selected[0] != undefined || selected[0] != null) {
                    handleChange({target: {value: selected[0].id, name: "gameModuleId"}});
                  }
                }}
              />
            </div>
            <button className="btn btn-block btn-hero-lg btn-hero-warning" onClick={()=> nextStep()}>
                Avançar
            </button>
            <button className="btn btn-block btn-hero-lg btn-hero-white text-warning" onClick={()=> prevStep()}>
                Voltar
            </button>
        </div>
    )
}