import React, { useState } from "react";

export default function CouponCard({data}){
    const [open, setOpen] = useState(false);

    function openLink(data){
        registerConversion(data)
        window.open(data.url, "_blank");
    }

    function registerConversion(){}

    return(
        <div class="block block-bordered row">
            <div className="col-3 py-3 text-center">
                <img src={data && data.image && data.image.url} style={{height: 50}}/>
            </div>
            <div class="col-9">
                <h3 className="mt-2">
                    {data && data.title}
                </h3>
                <span className="mt-1 text-success">
                    {data && data.promo}
                </span>
            </div>
            <div className="col-12 text-center">
                <hr/>

                <button className="btn btn-dual" onClick={()=> setOpen(!open)}>Ver regras 
                    {open === true ? 
                        <i className="ml-2 si si-arrow-up"/>
                    :
                        <i className="ml-2 si si-arrow-down"/>
                    }
                </button>
            </div>
            {open ? 
                <div class="col-12 block-content block-content-full block-content-sm bg-body-light">
                    <ul class="font-size-sm text-muted">
                        {data && data.rules.map(rule => (
                            <li className="mt-1">{rule}</li>
                        ))}
                    </ul>
                </div>
            : ""}
            <div class="col-12 block-content block-content-full block-content-sm font-size-sm bg-success row text-white" onClick={()=> openLink(data)}>
                <p className="col-6 mb-0">Exclusivo Clube Liga</p>
                <p className="col-6 text-right mb-0">{data && data.action}
                    <button className="btn btn-dual text-white">
                        <i className="fa fa-arrow-right"/>
                    </button>
                </p>
            </div>
        </div>
    )
    
}