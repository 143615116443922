import React, { useState } from "react";

export default function CheckPhoneStep({data, handleChange, nextStep, prevStep}) {
    const [query, setQuery] = useState("");

    return (
        <div>
            <p className="text-uppercase font-w700 font-size-sm text-center">
                Para sua segurança, digite o código de 4 dígito que enviamos para seu celular.
                <br/>
                <strong className="text-warning">{data && data.phone && (data.phone)}</strong>
            </p>
            <div className="form-group">
              <span>Digite o código de 4 dígitos</span>
              <input type="text" className="form-control" name="confirmationCode" onChange={evt => handleChange(evt)}/>
            </div>

            <p className="font-w700 font-size-sm text-center">
                <strong className="text-warning">Reenviar o código em xx</strong>
            </p>

            <button className="btn btn-block btn-hero-lg btn-hero-warning" onClick={()=> nextStep()}>
                Avançar
            </button>
            <button className="btn btn-block btn-hero-lg btn-hero-white text-warning" onClick={()=> prevStep()}>
                Voltar
            </button>
        </div>
    )
}