export function MoneyMask(v, n) {
  let i, f;
  i = Math.floor(v);
  f = v - i;
  if (n !== 0) {
    return `${i.toLocaleString()},${f.toFixed(n).substr(2)}`;
  } else {
    return `${i.toLocaleString()}`;
  }
}

export function CheckCreditCard(numb) {
  const regex = new RegExp("^[0-9]{13,19}$");
  if (!regex.test(numb)) {
    return false;
  }
  return luhnck(numb);
}


const luhnck = (val) => {
  let validsum = 0;
  let k = 1;
  for (let l = val.length - 1; l >= 0; l--) {
    let calck = 0;
    calck = Number(val.charAt(l)) * k;
    if (calck > 9) {
      validsum = validsum + 1;
      calck = calck - 10;
    }
    validsum = validsum + calck;
    if (k == 1) {
      k = 2;
    } else {
      k = 1;
    }
  }
  console.log("Resultados", val, validsum % 10 == 0);
  return validsum % 10 == 0;
};
