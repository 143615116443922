import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import SearchComponent from "./components/search";
import MainComponent from "./components/main";
import Layout from "@/app/core/layouts/main";
import Storage from "@/app/core/util/Storage";

const ProjectShell = props => <Layout {...props}>{renderRoutes(props.route.routes, props)}</Layout>;

const Redirecter = () => <Redirect to="/error/404" />;

export const DashboardRouter = {
  path: "/app",
  component: ProjectShell,
  routes: [
    {
      path: "/app",
      exact: true,
      component: MainComponent
    },
    {
      path: "/search",
      exact: true,
      component: SearchComponent
    },
    {
      path: "*",
      component: Redirecter
    }
  ]
};
