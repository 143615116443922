import React, {useState} from "react";
import Truck from "@/assets/img/truck.png";
import Boat from "@/assets/img/boat.png";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";

export default function HomeComponent({}){
    const [step, setStep] = useState(1);
    const [data, setData] = useState({});

    function handleChange(evt){
        setData({...data, [evt.target.name]:evt.target.value});
    }

    function goNacional(){
        setData({...data, type: 1});
        setStep(2);
    }

    function goInternacional(){
        setData({...data, type: 2});
        setStep(2);
    }
    
    function goSearch(){
        setStep(2);
    }

    function definePackage(){
        let error = false;
        let message = "Está faltando preencher ";
        if(data.originAddress == undefined || data.originAddress == ""){
            error = true;
            message += " Endereço de origem;"
        } 
        if(data.zipcode == undefined || data.zipcode == ""){
            error = true;
            message += " CEP de origem;"
        } 
        if(data.number == undefined || data.number == ""){
            error = true;
            message += " Número de origem;"
        } 
        if(data.address == undefined || data.address == ""){
            error = true;
            message += " Endereço de destino;"
        } 
        if(data.zipcodeDelivery == undefined || data.zipcodeDelivery == ""){
            error = true;
            message += " CEP de destino;"
        } 
        if(data.numberDelivery == undefined || data.numberDelivery == ""){
            error = true;
            message += " Número de destino;"
        } 

        if(error){
            toast.error(message);
        }else{
            setStep(3);
        }
    }

    function getQuotation(){
        let message = "Faltou preencher ";
        let error = false;

        if(data.height == undefined || data.height == ""){
            error = true;
            message += " Altura;"
        }
        if(data.width == undefined || data.width == ""){
            error = true;
            message += " largura;"
        }
        if(data.depth == undefined || data.depth == ""){
            error = true;
            message += " profundidade;"
        }
        if(data.weight == undefined || data.weight == ""){
            error = true;
            message += " peso;"
        }
        if(data.declaredValue == undefined || data.declaredValue == ""){
            error = true;
            message += " valor do conteúdo";
        }

        if(error){
            toast.error(message);
        }else{
            setStep(4)
        }
    }

    function current(){
        switch (step){
            case 1:
                return (
                    <div className="row">
                        <div className="col-md-2">
                        </div>
                        <div className="col-md-8 block block-fx-pop block-rounded">
                            <form onSubmit={evt => goSearch()}>
                                <div className="block-content">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Endereço de entrega..." name="address" value={data && data.address} onChange={evt => handleChange(evt)}/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="fa fa-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-2">
                        </div>
        
                        <div className="col-md-12">
                        <h2 className="content-heading font-w700">Sugestões</h2>
                        <div className="block block-rounded block-bordered block-fx-pop">
                            <div className="block-content row">
        
                            <a class="block block-rounded block-link-shadow bg-black-5 text-center col-4 " href="javascript:void(0)" onClick={() => goNacional()}>
                                <div class="block-content block-content-full mb-0">
                                    <img class="img-avatar  img-avatar-rounded" src={Truck} alt=""/>
                                </div>
                                <div class="block-content block-content-full">
                                <p class="font-w700 mb-0">
                                    Contação nacional
                                </p>
                                </div>
                            </a>
        
                            <a class="block block-rounded block-link-shadow bg-black-5 text-center col-4 " href="javascript:void(0)" onClick={() => goInternacional()}>
                                <div class="block-content block-content-full mb-0">
                                    <img class="img-avatar  img-avatar-rounded" src={Boat} alt=""/>
                                </div>
                                <div class="block-content block-content-full">
                                <p class="font-w700 mb-0">
                                    Cotação internacional
                                </p>
                                </div>
                            </a>
                            
                            <a class="block block-rounded block-link-shadow bg-black-5 text-center col-4 " href="javascript:void(0)">
                                <div class="block-content block-content-full mt-3 mb-0">
                                <i className="si si-pin fa-2x text-success"/>
                                </div>
                                <div class="block-content block-content-full">
                                <p class="font-w700 mb-0">
                                    Rastrear Pedido
                                </p>
                                </div>
                            </a>
                            </div>
                        </div>
        
                        <h2 className="content-heading font-w700 mt-3">Entregas ativas</h2>
                        {/* Colocar as rotas em andamento */}
                        </div>
                    </div>
                )
            case 2:
                return (
                    <form onSubmit={evt => definePackage()}>
                        <div className="row">
                                <div className="col-md-12">
                                    <div className="block block-rounded block-bordered block-fx-pop">
                                        <div className="block-header">
                                            <div className="block-title">
                                                Coleta
                                            </div>
                                        </div>
                                        <div className="block-content">
                                            <div className="block-content row">
                                                <div className="form-group col-12">
                                                    <span>Endereço de coleta</span>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Endereço de coleta..." name="originAddress" value={data && data.originAddress} onChange={evt => handleChange(evt)}/>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="fa fa-search"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-6">
                                                    <span>CEP</span>
                                                    <input type="text" className="form-control" name="zipcode" value={data && data.zipcode} onChange={evt => handleChange(evt)}/>
                                                </div>
                                                <div className="form-group col-6">
                                                    <span>Número</span>
                                                    <input type="text" className="form-control" name="number" value={data && data.number} onChange={evt => handleChange(evt)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block block-rounded block-bordered block-fx-pop">
                                        <div className="block-header">
                                            <div className="block-title">
                                                Entrega
                                            </div>
                                        </div>
                                        <div className="block-content">
                                            <div className="block-content row">
                                                <div className="form-group col-12">
                                                    <span>Endereço de coleta</span>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Endereço de coleta..." name="address" value={data && data.address} onChange={evt => handleChange(evt)}/>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="fa fa-search"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-6">
                                                    <span>CEP</span>
                                                    <input type="text" className="form-control" name="zipcodeDelivery" value={data && data.zipcodeDelivery} onChange={evt => handleChange(evt)}/>
                                                </div>
                                                <div className="form-group col-6">
                                                    <span>Número</span>
                                                    <input type="text" className="form-control" name="numberDelivery" value={data && data.numberDelivery} onChange={evt => handleChange(evt)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-hero-lg btn-hero-success" onClick={()=> definePackage()}>
                                        Avançar
                                    </button>
                                    <button type="button" className="btn btn-hero-lg btn-hero-white text-warning" onClick={()=> setStep(step -1)}>
                                        Voltar
                                    </button>
                                </div>
                        </div>
                    </form>
                )
            case 3:
                return (
                    <form onSubmit={evt => getQuotation()}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block block-rounded block-bordered block-fx-pop">
                                    <div className="block-header">
                                        <div className="block-title">
                                            Informações do pacote
                                        </div>
                                    </div>
                                    <div className="block-content">
                                        <div className="block-content row">
                                            <div className="form-group col-6">
                                                <span>Altura da caixa (cm)</span>
                                                <input type="number" className="form-control" name="height" value={data && data.height} onChange={evt => handleChange(evt)}/>
                                            </div>
                                            <div className="form-group col-6">
                                                <span>Largura da caixa (cm)</span>
                                                <input type="number" className="form-control" name="width" value={data && data.width} onChange={evt => handleChange(evt)}/>
                                            </div>
                                            <div className="form-group col-6">
                                                <span>Profundidade da caixa (cm)</span>
                                                <input type="number" className="form-control" name="depth" value={data && data.depth} onChange={evt => handleChange(evt)}/>
                                            </div>
                                            <div className="form-group col-6">
                                                <span>Peso (Kg)</span>
                                                <input type="number" className="form-control" name="weight" value={data && data.weight} onChange={evt => handleChange(evt)}/>
                                            </div>
                                            <div className="form-group col-6">
                                                <span>Qual é o valor do item?
                                                    <br/>
                                                    <small>O item será assegurado no valor definido por você logo abaixo.</small>
                                                </span>
                                                <CurrencyFormat
                                                    className={`form-control`}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    name="declaredValue"
                                                    value={data.declaredValue}
                                                    prefix={"R$"}
                                                    onValueChange={(values) => {
                                                        const { formattedValue, value } = values;
                                                        handleChange(
                                                        { target: { value: value, name: "declaredValue" } }
                                                        );
                                                    }}
                                                />
                                            </div>
                                            {data && data.type == 2 ? 
                                                <div className="col-12">
                                                    <div className="block-title">
                                                        Descreva para a alfândega o conteúdo da sua remessa
                                                    </div>
                                                </div>
                                            :""}

                                            {data && data.type == 2 ? 
                                                <div className="form-group col-6">
                                                    <span>Tipo de remessa</span>
                                                    <select className="form-control" name="contentType" value={data && data.contentType} onChange={evt => handleChange(evt)}>
                                                        <option>Selecione</option>
                                                        <option>Produtos</option>
                                                        <option>Documentos</option>
                                                    </select>
                                                </div>
                                            :""}
                                            {data && data.type == 2 ? 
                                                <div className="form-group col-6">
                                                    <span>Quantos itens iguais está levando?</span>
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <input type="number" className="form-control" name="contentTypeQtd" value={data && data.contentTypeQtd} onChange={evt => handleChange(evt)}/>
                                                        </div>
                                                        <div className="col-8">
                                                            <select className="form-control" name="contentType" value={data && data.contentType} onChange={evt => handleChange(evt)}>
                                                                <option>Selecione</option>
                                                                <option>Produtos</option>
                                                                <option>Documentos</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            :""}
                                            {data && data.type == 2 ? 
                                                <div className="form-group col-6">
                                                    <span>Quanto pesa cada item?</span>
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <input type="number" className="form-control" name="weightIndividualQtd" value={data && data.weightIndividualQtd} onChange={evt => handleChange(evt)}/>
                                                        </div>
                                                        <div className="col-8">
                                                            <select className="form-control" name="weightIndividual" value={data && data.weightIndividual} onChange={evt => handleChange(evt)}>
                                                                <option>Selecione</option>
                                                                <option>Kg</option>
                                                                <option>Libras</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            :""}
                                            {data && data.type == 2 ? 
                                                <div className="form-group col-6">
                                                    <span>Qual o proposito da entrega?</span>
                                                    <select className="form-control" name="propose" value={data && data.propose} onChange={evt => handleChange(evt)}>
                                                        <option>Comercial</option>
                                                        <option>Particular</option>
                                                    </select>
                                                </div>
                                            :""}
                                            {data && data.type == 2 ? 
                                                <div className="form-group col-12">
                                                    <span>Descrição do item</span>
                                                    <textarea className="form-control" name="description" value={data && data.description} onChange={evt => handleChange(evt)}/>
                                                </div>
                                            :""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <button type="button" className="btn btn-hero-lg btn-hero-success" onClick={()=> getQuotation()}>
                                    Avançar
                                </button>
                                <button type="button" className="btn btn-hero-lg btn-hero-white text-warning" onClick={()=> setStep(step -1)}>
                                    Voltar
                                </button>
                            </div>
                        </div>
                    </form>
                )
            case 4:
                return (
                    <form onSubmit={evt => definePackage()}>
                        <div className="row">
                                <div className="col-md-12">
                                    <div className="block block-rounded block-bordered block-fx-pop">
                                        <div className="block-header">
                                            <div className="block-title">
                                                Entrega
                                            </div>
                                        </div>
                                        <div className="block-content">
                                            <div className="block-content row">
                                                <div className="form-group col-12">
                                                    
                                                    <div class="block block-rounded block-fx-pop mb-2 js-appear-enabled animated fadeIn" data-toggle="appear">
                                                        <div class="block-content block-content-full border-left border-3x border-success">
                                                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                                                <div class="p-1 p-md-3">
                                                                    <h3 class="h4 font-w700 mb-1">Total Express</h3>
                                                                    <p class="font-size-sm mb-2">
                                                                        <a class="mr-1 mb-1" href="javascript:void(0)"></a>
                                                                    </p>
                                                                    <p class="font-size-sm text-muted mb-0">
                                                                        Sexta 14 ás 08:00
                                                                    </p>
                                                                </div>
                                                                <div class="p-1 p-md-3">
                                                                    <a class="btn btn-outline-secondary btn-rounded px-3 mr-1 my-1" href="javascript:void(0)">
                                                                        R$119,80
                                                                    </a>
                                                                    <a class="btn btn-outline-success btn-rounded px-3 my-1" href="javascript:void(0)">
                                                                        <i class="si si-check mr-1"></i> Confirmar
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-hero-lg btn-hero-white text-warning" onClick={()=> setStep(step -1)}>
                                        Voltar
                                    </button>
                                </div>
                        </div>
                    </form>
                )


        }
    }

    return(
        <div>
            {step && current()}
        </div>
      
    )
}