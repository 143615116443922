import React, { useState } from "react";

export default function Chat() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState({});

  function selectPerson(person) {
    setPage(1);
    setSelected(person);
  }

  let data = [
    {
      id: 0,
      name: "Bruno",
      email: "teste@teste.com",
      department: "Preparação"
    },
    {
      id: 1,
      name: "Kauã da Silva",
      email: "fabiorsilvajr@gmail.com",
      department: "Preparação"
    },
    {
      id: 2,
      name: "Fabio",
      email: "fabio.silva@power-seg.net",
      department: "Preparação"
    },
    {
      id: 3,
      name: "Mateus Henrique",
      email: "fabio.silva@power-seg.net",
      department: "Orçamento"
    },
    {
      id: 4,
      name: "Viviane Campos",
      email: "teste@teste.com",
      department: "Análise"
    },
    {
      id: 5,
      name: "Victor Augusto",
      email: "victor_augusto_silva@hotmail.com",
      department: "Orçamento"
    },
    {
      id: 6,
      name: "Fabio Rodrigues",
      email: "fabio@teste.com",
      department: "Orçamento"
    }
  ];

  const PageOne = prop => {
    return (
      <div className="block mb-0">
        <div className="block-content block-content-sm block-content-full bg-body">
          <span className="text-uppercase font-size-sm font-w700">
            Contatos internos
          </span>
        </div>
        <div className="block-content">
          <ul className="nav-items" id="usersHasaAside">
            {data.map(person => {
              return (
                <li>
                  <a
                    class="media py-2"
                    href="#"
                    onClick={() => selectPerson(person)}
                  >
                    <div class="mx-3 overlay-container">
                      <img
                        class="img-avatar img-avatar48"
                        src="assets/media/avatars/avatar8.jpg"
                        alt=""
                      />
                      <span class="overlay-item item item-tiny item-circle border border-2x border-white bg-success"></span>
                    </div>
                    <div class="media-body">
                      <div class="font-w600">{person.name}</div>
                      <div class="font-w400 font-size-sm text-muted">
                        Dpto : {person.department}
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>

          <div className="block-content block-content-sm block-content-full bg-body">
            <span className="text-uppercase font-size-sm font-w700">
              Contatos dos clientes
            </span>
          </div>
          <div className="form-control">
            <input
              className="form-control"
              placeholder="Filtrar contatos de clientess"
              type="text"
              id="filterUsersAside"
            />
          </div>
          <div className="block-content">
            <ul className="nav-items" id="clientContactsAside">
              <li></li>
            </ul>
          </div>
        </div>

        <div
          className="tab-pane pull-x fade fade-up"
          id="so-chat"
          role="tabpanel"
        >
          <form action="#" method="post" onsubmit="return false;">
            <div className="block mb-0">
              <div className="block-content block-content-sm block-content-full bg-body">
                <p className="mt-3 mb-0 d-lg-flex justify-content-lg-between">
                  <a
                    className="btn btn-sm btn-light d-block d-lg-inline-block mb-1"
                    href="#"
                    id="goBackAside"
                  >
                    <i className="fa fa-fw fa-reply mr-1"></i> Voltar
                  </a>
                  <a
                    className="btn btn-sm btn-light d-block d-lg-inline-block mb-1"
                    href="#"
                    id="userNameAsideContact"
                  ></a>
                </p>
              </div>
              <div
                className="row"
                style={{ height: "530px" }}
                id="messagesField"
              ></div>
              <div className="block-content block-content-full">
                <div className="block-content row justify-content-center border-top">
                  <div className="col-12">
                    <textarea
                      className="form-control"
                      id="messageAsideContact"
                    ></textarea>
                  </div>
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-block btn-hero-success"
                      onclick="sendMessageAside()"
                    >
                      <i className="nav-main-link-icon si si-cursor"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const PageTwo = prop => {
    return (
      <div className="block-content block-content-sm block-content-full bg-body">
        <p className="mt-3 mb-0 d-lg-flex justify-content-lg-between">
          <a
            className="btn btn-sm btn-light d-block d-lg-inline-block mb-1"
            onClick={() => setPage(0)}
          >
            <i className="fa fa-fw fa-reply mr-1"></i> Voltar
          </a>
          <a
            className="btn btn-sm btn-light d-block d-lg-inline-block mb-1"
            href="#"
            id="userNameAsideContact"
          >
            {selected.name}
          </a>
        </p>
      </div>
    );
  };

  return (
    <>
      <div className="simplebar-content" style={{ overflowX: "hidden" }}>
        <div
          className="bg-image"
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/assets/media/various/bg_side_overlay_header.jpg')`
          }}
        >
          <div className="bg-primary-op">
            <div className="content-header">
              <a className="img-link mr-1 " href="#">
                <input
                  type="file"
                  name="profilePictureAside"
                  id="profilePictureAside"
                  accept="image/*"
                  style={{ visibility: "hidden" }}
                />
                <label for="profilePictureAside">
                  <figure>
                    <img
                      src="assets/media/avatars/avatar8.jpg"
                      id="userPicture"
                      className="img-avatar img-avatar48"
                    />
                  </figure>
                </label>
              </a>
            </div>
          </div>
        </div>
        {page == 0 && PageOne()}
        {page == 1 && PageTwo()}

        <div className="content-side">
          <div className="block block-transparent pull-x pull-t">
            <ul
              className="nav nav-tabs nav-tabs-block nav-justified js-tabs-enabled"
              data-toggle="tabs"
              role="tablist"
            >
              <li className="nav-item">
                <a className="nav-link active" href="#so-people">
                  <i className="far fa-fw fa-user-circle"></i>
                </a>
              </li>
            </ul>
            <div className="block-content tab-content overflow-hidden">
              <div
                className="tab-pane pull-x fade fade-up  show active"
                id="so-people"
                role="tabpanel"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
