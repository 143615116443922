import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { TeamType, TeamTypeObj } from "@/app/core/util/helpers/TeamHelper";
import React, { useState, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import InputMask from "react-input-mask";

export default function TeamCategoryStep({data, categories, fieldTypes, handleChange, nextStep, prevStep, uploadFile}) {
    const [query, setQuery] = useState("");
    const inputFile = useRef(null);

    function onUploadClick() {
        inputFile.current.click();
    }
   
    return (
        <div>
            <p className="text-uppercase font-w700 font-size-sm text-center">
                Qual o tipo e categoria do time?
            </p>
            <div className="form-group">
                <span>Nome do time</span>
                <input className="form-control" name="name" value={data && data.name} onChange={evt => handleChange(evt)}/>
            </div>
            <div className="form-group">
                <span>Cor da camisa</span>
                <input className="form-control" name="color" value={data && data.color} onChange={evt => handleChange(evt)}/>
            </div>
            <div className="form-group">
                <span>Tipo</span>
                <select className="form-control" name="type" value={data && data.type} onChange={evt => handleChange(evt, FieldTypeObj.Number)}>
                    <option>Selecione</option>
                    {TeamType.map(item => (
                        <option value={item.value}>{item.name}</option>
                    ))}
                </select>
            </div>
            <div className="form-group">
                <span>Categoria</span>
                <Typeahead
                    options={categories}
                    labelKey={(option) => `${option.name}`}
                    onSearch={(q) => setQuery(q)}
                    id="advanced-filter-state"
                    placeholder="Selecionar Categoria"
                    onChange={(selected) => {
                        if (selected[0] != undefined || selected[0] != null) {
                            handleChange({target: {value: selected[0].id, name: "ageGroupId"}});
                        }
                    }}
                />
            </div>
            <div className="form-group">
                <span>CEP</span>
                <InputMask
                    mask="99999-999"
                    maskChar=""
                    type="text"
                    className={`form-control`}
                    id="advanced-filter-document"
                    name="cep"
                    value={data && data.cep}
                    placeholder="00000-000"
                    onChange={(event) => {
                        handleChange({
                        target: {
                            name: "cep",
                            value: event.target.value.replace(/\D/g, ""),
                        },
                        });
                    }}
                    />
            </div>
            <div className="form-group">
                <span>Endereço</span>
                <input className="form-control" name="address" value={data && data.address} onChange={evt => handleChange(evt)}/>
            </div>
            <div className="ml-2 mr-2 form-group bg-gray-light row block-rounded p-2" onClick={() => onUploadClick()}>
                <div className="col-10">
                    <strong>Envie o Brasão do time</strong><br/>
                    <small>Escolha uma imagem com fundo branco</small>
                </div>
                <div className="col-2 text-right">
                    {data && data.files !== undefined ? 
                    <i className="si si-check text-success py-3"/>
                    :
                    <i className="fa fa-upload py-3"/>
                    }
                </div>
            </div>

            {data && data.type === TeamTypeObj.Principal ?
                <div>
                    <div className="form-group">
                        <span>Nome do campo/quadra</span>
                        <input className="form-control" name="arenaName" value={data && data.arenaName} onChange={evt => handleChange(evt)}/>
                    </div>
                    <div className="form-group">
                        <span>Tipo de campo/quadra</span>
                        <Typeahead
                            options={fieldTypes}
                            labelKey={(option) => `${option.name}`}
                            onSearch={(q) => setQuery(q)}
                            id="advanced-filter-state"
                            placeholder="Selecionar Categoria"
                            onChange={(selected) => {
                                if (selected[0] === undefined || selected === null) {
                                } else {
                                    handleChange({target: {value: selected[0].id, name: "fieldTypeId"}});
                            }
                            }}
                        />
                    </div>
                </div>
            :""}

            <button className="btn btn-block btn-hero-lg btn-hero-warning" onClick={()=> nextStep()}>
                Avançar
            </button>
            <button className="btn btn-block btn-hero-lg btn-hero-white text-warning" onClick={()=> prevStep()}>
                Voltar
            </button>
            <input
              style={{ visibility: "hidden" }}
              accept="image/*"
              type="file"
              ref={inputFile}
              onChange={(event) => {
                uploadFile(event.currentTarget.files);
              }}
            />
        </div>
    )
}