import React from "react";
import { useState, useRef } from "react";
import DocInput from "../files/DocInput";
import { getDownloadURL, getStorage, ref as refStor, uploadBytesResumable } from "firebase/storage";
import { child, get, getDatabase, push, ref, remove } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";
import { useEffect } from "react";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function UserPosts({userId, edit}){
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const db = getDatabase(firebaseApp);
    
    useEffect(()=>{
        if(userId != undefined){
            loadPosts()
        }
        console.log("userId", userId);
    },[userId])
    
    function loadPosts(){
        get(child(ref(db), `posts/${userId}`)).then((snapshot) => {
            let posts = [];
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    posts.push({
                        ...notYet[s],
                        id: s,
                    })
                }
                console.log("Pictures", posts)
                setData(posts);
            }
        });
    }

    function uploadFile(files){
        setLoading(true);
        
        const storage = getStorage();
        const reference = refStor(storage, `${data.id}`);

        // 'file' comes from the Blob or File API
        const uploadTask = uploadBytesResumable(reference, files[0]);
        uploadTask.on('state_changed',
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                console.log('Upload is paused');
                break;
                case 'running':
                console.log('Upload is running');
                break;
            }
            },
            (error) => {
                setLoading(false);
            // Handle unsuccessful uploads
            },
            () => {
                setLoading(false);
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    push(ref(db, `posts/${userId}`), {
                        url: downloadURL,
                        name: "Sem nome",
                    });
                    loadPosts();
                });
            }
        )
    }

    function openImage(item){}
    
    function deleteImage(id){
        if(id != undefined && userId != undefined){
            if(window.confirm(Message.DeleteConfirm)){
                remove(ref(db, `posts/${userId}/${id}`));
                loadPosts();
            }
        }
    }
    

    return (
        <div className="row mb-5">
            {edit == true ? 
                <div className="col-12 text-center py-3">
                    <DocInput 
                        name={"Subir fotos"}
                        multiple={false}
                        uploadFile={evt => uploadFile(evt)}
                        loading={loading} 
                        className="btn btn-block btn-warning"/>
                </div>
            :""}

            {data && data.length == 0 ? 
                <div className="col-12 text-center" style={{height: 280}}>
                    <h3 className="text-muted mt-3">
                        Nenhum post adicionado
                    </h3>
                </div>
            :""}
            {data && data.map(item => (
                <div className="col-6">
                    <div class="block block-rounded block-fx-pop">
                        <div class="block-content p-0 overflow-hidden">
                            <a
                            class="img-link img-link-zoom-in img-thumb img-lightbox img-fluid-100"
                            data-toggle="layout"
                            data-action="side_overlay_open"
                            target="_blank"
                            onClick={() => openImage(item)}
                            >
                            <img class="img-fluid rounded-top" src={item && item.url} alt="" />
                            </a>
                        </div>
                        <div class="ml-1 mr-1 mt-1 mb-1">
                            <div class="row">
                                <div class="col-8"></div>
                                <div class="col-4 text-right">
                                    {edit == true ? (
                                    <button
                                        className="btn btn-sm btn-link text-dark"
                                        title="Deletar"
                                        onClick={() => deleteImage(item.id)}
                                    >
                                        <i className="si si-close text-danger"></i>
                                    </button>
                                    ) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}