import React from "react";
import Logo from "@/assets/img/logo.png";
import { useState } from "react";
import { useEffect } from "react";
import { child, get, getDatabase, push, ref, remove, update } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function PictureItem({teamId, picture, reload}){
    const [data, setData] = useState({});
    const [edit, setEdit] = useState(false);
    const db = getDatabase(firebaseApp);

    useEffect(()=>{
        console.log("foto", picture, teamId)
        setData(picture);
    },[picture])

    function handleEdit(){
        if(edit === true){
            updatePicture()
            setEdit(!edit);
        }else{
            setEdit(!edit);
        }
    }

    function updatePicture(){
        if(data.id != undefined){
            update(ref(db, `teams/${teamId}/images/${data.id}`), {...data});
            reload();
        }
    }

    function removePicture(){
        if(window.confirm(Message.DeleteConfirm)){
            remove(ref(db, `teams/${teamId}/images/${data.id}`));
            reload();
        }
    }

    return (
        <div class="block block-rounded block-fx-pop bg-light-gray">
            <div class="row">
                <div class="col-3">
                    <img className="img-avatar img-avatar-thumb img-avatar-rounded border-gray border-2x border" src={data && data.url} />
                </div>
                <div class="col-5 p-3">
                    {edit === true ?
                        <input type="text" className="form-control" value={data && data.name} onChange={evt => setData({...data, name: evt.target.value})}/>
                    :
                        <span className="">
                            {data && data.name}
                        </span>
                    }
                </div>
                <div class="col-4 text-right">
                    <a class="btn btn-sm btn-dual mt-3" href="javascript:void(0)" onClick={() => handleEdit()}>
                        <i className="far fa-edit"/>
                    </a>
                    <a class="btn btn-sm btn-dual mt-3" href="javascript:void(0)" onClick={() => removePicture()}>
                        <i className="si si-close"/>
                    </a>
                </div>
            </div>
        </div>
    )
}