import React, { useState } from "react";
import Logo from "@/assets/img/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GenericHeader from "@/app/core/layouts/main/components/GenericHeader";
// import { ForgotPassword } from "@/app/core/base/controllers/UsersController";

export default function SignIn(props) {
  const [email, setEmail] = useState(null);

  function recover() {
    if (email === null || email === "") {
      toast.error("Escreva o seu email para recuperar a senha");
      return;
    }
    // ForgotPassword(email, (res) => {
    //   toast.info("Foi enviado um email com o link para alterar a senha");
    //   setTimeout(() => {
    //     goAuth();
    //   }, 1500);
    // });
  }

  function goAuth() {
    window.location = "#/auth";
  }

  return (
    <div id="page-container">
      <main id="main-container">
        <div
          className="bg-image"
          style={{
            backgroundImage: "url('assets/media/photos/photo22@2x.jpg')",
          }}
        >
          <GenericHeader register={false}/>
          <div className="row no-gutters bg-primary-op">
            <div className="hero-static col-md-6 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <p className="text-center text-uppercase font-w700 font-size-sm text-warning">
                  Redefinir a senha
                </p>

                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-8 col-xl-6">
                    <div className="js-validation-signin">
                      <div className="py-3">
                        <div className="form-group">
                          <span>E-mail</span>
                          <input
                            type="email"
                            className="form-control form-control-lg form-control-alt"
                            id="email"
                            name="email"
                            onChange={(evt) => setEmail(evt.target.value)}
                            placeholder="E-mail"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          onClick={() => recover()}
                          className="btn btn-block btn-hero-lg btn-hero-warning"
                        >
                          <i className="fa fa-fw fa-arrow-right mr-1" />{" "}
                          Redefinir
                        </button>
                        <p className="mt-3 mb-0 text-center">
                          <a
                            className="btn btn-sm btn-light d-block d-lg-inline-block mb-1"
                            onClick={() => goAuth()}
                          >
                            <i className="fa fa-arrow-left text-muted mr-1" />{" "}
                            Voltar para login
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
              <div className="p-3">
                <p className="display-4 font-w700 text-white mb-3">
                    {/* Colocar um texto legal */}
                  </p>
                  <p className="font-size-lg font-w600 text-white-75 mb-0">
                    Copyright <b>Liga Esporte Amador</b> &copy;{" "}
                    <span className="js-year-copy">
                      {new Date().getFullYear()}
                    </span>
                  </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer className="toastr" />
    </div>
  );
}
