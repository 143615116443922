import React from "react";
import { renderRoutes } from "react-router-config";
import MainComponent from "./main";
import Layout from "@/app/core/layouts/main";

const EmptyRouter = props => <Layout {...props}>{renderRoutes(props.route.routes, props)}</Layout>;

const OffersRouter = () => ({
  path: "/offers",
  exact: true,
  component: EmptyRouter,
  routes: [
    {
      path: "",
      component: MainComponent
    }
  ]
});

export default OffersRouter();
