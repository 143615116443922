import { firebaseApp } from "@/app/core/base/ControllerBase";
import SportsController from "@/app/core/base/controllers/SportsController";
import { child, get, getDatabase, ref } from "firebase/database";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function Contact(props){
    const [data, setData] = useState([]);
    const db = getDatabase(firebaseApp);

    useEffect(()=>{
        loadContactForms();
    },[]);

      function loadContactForms(){
        // SportsController().Insert();
        get(child(ref(db), `contacts`)).then((snapshot) => {
            let contactData = [];
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    contactData.push({
                        ...notYet[s],
                        id: s,
                    })
                }

                console.log("Resultadops", contactData)
                setData(contactData);
              }
          });
      }

      return (
        <div className="row m-5">
            <div className="block block-rounded block-fx-pop col-md-12">
                <div className="block-content block-content-full block-content-sm">
                    <h2 className="content-heading">
                        <i className="fa fa-angle-right text-muted mr-1"></i>{" "} Contato
                    </h2>
                </div>
                <div className="block-content block-content-full">
                    {data && data.map(item => (
                        <div class="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn p-2">
                            <table class="table table-borderless table-vcenter mb-0">
                                <tbody><tr>
                                    <td class="js-task-content font-w600 pl-0">
                                        <strong>
                                            {item.title}
                                        </strong>
                                        <br/>
                                        {item.route}
                                    </td>
                                    <td class="text-right">
                                        <button type="button" class="js-task-star btn btn-sm btn-link text-info">
                                            <i class="fa fa-arrow-right"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody></table>
                        </div>
                    ))}
                </div>
            </div>
        </div>
      )
}