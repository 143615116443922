import { getDatabase, get, ref, child, push, Database } from "firebase/database";
import { firebaseApp } from "../ControllerBase";

export default function SportsController(){
    const db = getDatabase(firebaseApp);

    async function List(){
        let data = [];
        await get(child(ref(db), `sports`)).then(async (snapshot) => {
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    data.push({
                        ...notYet[s],
                        key: s,
                    })
                }
            }
        });

        return data;
    }
    
    async function Insert(){
        push(ref(db, 'contacts'), {
            title: "Atendimento por e-mail",
            route: "contato@ligaesporteamador.com.br",
            type: 0,
        });
        push(ref(db, 'contacts'), {
            title: "Atendimento pelo WhatsApp",
            route: "+5511991315267",
            type: 1,
        });
        push(ref(db, 'contacts'), {
            title: "Central de Atendimento",
            route: "+5511991315267",
            type: 3,
        });
    }

    return {
        List,
        Insert,
    }

}