import React, { useEffect, useState } from "react";
import "./assets/nav.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Nav from "./components/Nav";
import Storage from "@/app/core/util/Storage";
import SideOverlay from "./components/SideOverlay";
import Chat from "./components/Chat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Layout(props) {
  const [showModal, setShowModal] = useState(false);
  const [showProspectModal, setShowProspectModal] = useState(false);
  const [menu, setMenu] = useState([
    {
      name: "Principal",
      routerLink: "/",
      icon: "",
    },
    {
      name: "Marcar Jogo",
      routerLink: "/newGame",
      icon: "",
    },
    {
      name: "Meu time",
      routerLink: "/myteam",
      icon: "",
    },
    {
      name: "Perfil",
      routerLink: `/profile`,
      icon: "",
    },
    {
      name: "Quero ser jogador",
      routerLink: "/#",
      icon: "",
    },
    {
      name: "Alugar",
      children: [
        {
          name: "Quadra ou Campo",
          routerLink: "/#",
          icon: "",
        },
        {
          name: "Transporte",
          routerLink: "/#",
          icon: "",
        }
      ]
    },
    {
      name: "Eventos",
      routerLink: "/#",
      icon: "",
    },
    {
      name: "Notícias",
      routerLink: "/#",
      icon: "",
    },
    {
      name: "Ofertas exclusivas",
      routerLink: "/offers",
      icon: "",
    },
    {
      name: "Cupons de desconto",
      routerLink: "/coupons",
      icon: "",
    },
    {
      name: "Manual dos esportes",
      routerLink: "/#",
      icon: "",
    },
]);
  const [settings, setSettings] = useState([
    {
      name: "A Liga Esporte Amador",
      routerLink: "/#",
      icon: "",
    },
    {
      name: "Como functiona",
      routerLink: "/#",
      icon: "",
    },
    {
      name: "Fale com a Liga",
      routerLink: "/contact",
      icon: "",
    },
    {
      name: "Termos e políticas",
      routerLink: "/terms",
      icon: "",
    },
]);
  const user = Storage.getUserData();
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    if(user != null){
    }
  }, [user]);

  let pageContainerRef = React.createRef();
  const toggleOverlay = () => {
    const pageContainer = pageContainerRef.classList;
    pageContainer.remove("side-overlay-o");
    pageContainer.remove("sidebar-o");
  };
  const toggleNav = () => {
    const pageContainer = pageContainerRef.classList;
    if (window.innerWidth > 991) {
      pageContainer.remove("sidebar-o-xs");
      pageContainer.toggle("sidebar-o");
    } else {
      pageContainer.remove("sidebar-o");
      pageContainer.toggle("sidebar-o-xs");
    }
  };


  return (
    <div
      ref={(el) => (pageContainerRef = el)}
      id="page-container"
      className={`enable-page-overlay side-scroll page-header-fixed main-content-narrow sidebar-white page-header-${Storage.getTheme()} `}
    >
      <div id="page-overlay" onClick={() => toggleOverlay()} />
      <Nav toggleNav={toggleNav} menu={menu} settings={settings}/>
      <Header
        showModal={() => setShowModal(!showModal)}
        showProspectModal={() => setShowProspectModal(!showProspectModal)}
        toggleNav={toggleNav}
        {...props.location}
      />
      <main id="main-container">{props.children}</main>
      <Footer />
      <SideOverlay style={{ width: "320px" }} title="Chat">
        <Chat />
      </SideOverlay>
      <ToastContainer className="toastr" />
    </div>
  );
}
