import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CouponCard from "./CouponCard";
import SportsController from "@/app/core/base/controllers/SportsController";
import { child, get, getDatabase, ref } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";

export default function Coupons(props){
    const [data, setData] = useState([]);
    const db = getDatabase(firebaseApp);

    useEffect(()=>{
        // SportsController().Insert();
        loadCoupons()
    },[]);

    function loadCoupons(){
        get(child(ref(db), `coupons`)).then((snapshot) => {
            let coupons = [];
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    coupons.push({
                        ...notYet[s],
                        id: s,
                    })
                }
                setData(coupons);
              }
          });
    }

      return (
        <div className="content row mt-3">
            <div className="col-md-12">
                <h3 className="text-muted"><i className="fa fa-arrow-left mr-1"/> Cupons de desconto</h3>
            </div>
            {data && data.map(item => (
                <div className="col-xl-6">
                    <CouponCard data={item}/>
                </div>
            ))}
        </div>
      )
}