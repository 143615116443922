import React, { useState } from "react";
import { withFormik, Form, Field } from "formik";

function SignInFormComponent({ values, handleChange, submit, loading }) {
  const [visible, setVisible] = useState(false);
  return (
    <Form className="js-validation-signin" method="post">
      <div className="py-3">
        <div className="form-group">
          <Field
            type="text"
            className="form-control form-control-lg form-control-alt"
            id="email"
            name="email"
            placeholder="Usuário"
          />
        </div>
        <div className="form-group">
          <div className="input-group">
            <Field
              type={visible === true ? "text":"password"}
              className="form-control form-control-lg form-control-alt"
              id="password"
              name="password"
              placeholder="Senha"
            />
            <div className="input-group-append">
                <span className="input-group-text" onClick={() => setVisible(!visible)}>
                    <i className={
                      visible === true
                        ? "far fa-fw fa-eye"
                        : "far fa-fw fa-eye-slash"
                    }/>
                </span>
            </div>
          </div>
          <p className="mt-3 mb-0 text-center">
              <a
                className="d-block text-warning mb-1"
                onClick={() => (window.location = "#/recover")}
              >
                Recuperar senha
              </a>
            </p>
        </div>
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="btn btn-block btn-hero-lg btn-hero-warning"
        >
          <i
            className={
              loading === true
                ? "fa fa-circle-notch fa-spin"
                : "fa fa-fw fa-sign-in-alt mr-1"
            }
          />{" "}
          Entrar
        </button>
        
        <p className="mt-3 mb-0 text-center">
          <a
            className="btn btn-sm btn-light d-block d-lg-inline-block mb-1"
            onClick={() => (window.location = "#/register")}
          >
            <i className="fa fa-plus text-muted mr-1" /> Não tem conta?
            Cadastre-se
          </a>
        </p>
      </div>
    </Form>
  );
}

const SignInForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ email, password }) {
    return {
      email: email || "",
      password: password || "",
    };
  },
  handleSubmit(values, { props }) {
    props.submit({
      values,
    });
  },
})(SignInFormComponent);

export default SignInForm;
