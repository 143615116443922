import { ContentType } from "@/app/core/util/helpers/ContentHelper";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { child, get, getDatabase, ref } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";

export default function Offers(props){
    const [data, setData] = useState([]);
    const db = getDatabase(firebaseApp);

    useEffect(()=>{
        loadCoupons();
    },[]);

    function openLink(item){
        registerConversion(item)
        window.open(item.url, "_blank");
    }

    function loadCoupons(){
        get(child(ref(db), `offers`)).then((snapshot) => {
            let coupons = [];
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    coupons.push({
                        ...notYet[s],
                        id: s,
                    })
                }
                setData(coupons);
              }
          });
    }

    function registerConversion(){}

      return (
        <div className="content row mt-3">
            <div className="col-md-12">
                <h3 className="text-muted"><i className="fa fa-arrow-left mr-1"/> Ofertas exclusivas</h3>
            </div>
            {data && data.map(item => (
                <div class="block block-bordered col-md-6" onClick={()=> openLink(item)}>
                    <div class="block-content block-content-full bg-image p-7" style={{
                        backgroundImage: `url('${item.image && item.image.url}')`,
                        height: 414
                    }}>
                    </div>
                    <div class="block-content block-content-full block-content-sm text-white row" style={{backgroundColor: item.color}}>
                        <span className="col-7 mt-1">
                            {item && item.promo}
                        </span>
                        <span className="col-4">
                            <small>
                                
                                {item && item.action}
                            </small><br/>
                            <strong>{item && item.section}</strong>
                        </span>
                        <div className="col-1">
                            <button className="btn btn-dual text-white mt-1">
                                <i className="fa fa-arrow-right"/>
                            </button>
                        </div>
                    </div>
                    <div class="block-content block-content-full block-content-sm bg-body-light">
                            <div class="font-size-sm text-muted">{item && item.tags && item.tags.map(tag => <span className="text-warning mr-2">{tag}</span>)}</div>
                            <div class="font-w600">{item.title}</div>
                        </div>
                        <div class="block-content block-content-full">
                            <p>{item && item.description}</p>
                        </div>
                </div>
            ))}
        </div>
      )
}