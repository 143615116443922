import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Logo from "@/assets/img/logo.png";
import Storage from "@/app/core/util/Storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountController from "@/app/core/base/controllers/UsersController";
import { HandleAuthErrors } from "@/app/core/util/helpers/ErrorHelper";
import TeamRegister from "./components/TeamRegister";
import BaseUserRegister from "./components/BaseUserRegister";
import PlayerRegister from "./components/PlayerRegister";
import GenericHeader from "@/app/core/layouts/main/components/GenericHeader";
import { useEffect } from "react";


export default function SignIn(props) {
  const [authenticate, setAuthenticate] = useState(false);
  const [teamId, setTeamId] = useState(null);
  const [route, setRoute] = useState(0);

  function goLogin(){
    window.location = `#/auth`
  }

  useEffect(()=>{
    checkUrlSteps()
  },[])

  function checkUrlSteps() {
    let param = props.location.search;
    let teamId = "";
    if(param.split("?")[1] !== undefined){

      let item = param.split("?")[1].split("&"),
        returnBool = false;
  
      for (var i = 0; i < item.length; i++) {
        let parr = item[i].split("=");
        
        if (parr[0] == "teamId" && parr[0] != undefined) {
          let itens = decodeURIComponent(parr[1]);
          let photoSteps = itens.split(",");
  
          console.log("Base Time", itens)
          setTeamId(itens);
          teamId = itens
          returnBool = true;
        }
      }
  
      if (returnBool === true) {
        console.log("Time", process)
        loadTeam(process);
      }
    }
  }

  function loadTeam(id){
    setRoute(2);
  }

  if (authenticate) return <Redirect to="/app" />;
  return (
    <div id="page-container">
      <main id="main-container">
        <div
          className="bg-image"
          style={{
            backgroundImage: "url('assets/media/photos/photo22@2x.jpg')",
          }}
        >
          <GenericHeader register={false}/>
          <div className="row no-gutters bg-primary-op">
            <div className="hero-static col-md-6 d-flex align-items-center bg-white">
              <div className="p-3 w-100">

                <div className="row no-gutters justify-content-center">
                    {route == 0 ? 
                        <div className="col-sm-8 col-xl-8 pl-3 pr-3 text-center">
                            <div className="mb-3 text-center">
                            <p className="text-uppercase font-w700 font-size-sm text-warning mb-0">
                                Olá, seja bem-vindo!
                            </p>
                            <p className="font-w700 font-size-sm text-muted">
                                Escolha uma das opções de cadastro
                            </p>
                            </div>
                            <button className="btn btn-block btn-hero-lg btn-hero-warning" onClick={()=> setRoute(1)}>
                                Cadastrar Time
                            </button>
                            <button className="btn btn-block btn-hero-lg btn-hero-warning" onClick={()=> setRoute(2)}>
                                Quero ser jogador
                            </button>
                            <button className="btn btn-block btn-hero-lg btn-hero-warning" onClick={()=> setRoute(3)}>
                                Cadastre-se no Clube LIGA
                            </button>
                            <p className="text-uppercase font-w700 font-size-sm text-warning py-3">
                                Para que serve os cadastros?
                            </p>
                            <button className="btn btn-block btn-hero-lg btn-hero-white text-warning" onClick={()=>goLogin()}>
                                Já tenho uma conta
                            </button>
                        </div>
                    :""}
                    {route === 1 ? <TeamRegister goBack={() => setRoute(0)}/>:""}
                    {route === 2 ? <BaseUserRegister goBack={() => setRoute(0)} teamId={teamId}/>:""}
                    {route === 3 ? <BaseUserRegister goBack={() => setRoute(0)}/>:""}
                </div>
              </div>
            </div>

            <div className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
              <div className="p-3">
                <p className="display-4 font-w700 text-white mb-3">
                  {/* Colocar um texto legal */}
                </p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Copyright <b>Liga Esporte Amador</b> &copy;{" "}
                  <span className="js-year-copy">
                    {new Date().getFullYear()}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
}
