export function DateToPast(date) {
  let dta = Math.floor(Math.abs(new Date() - new Date(date)) / 1000 / 60);

  if (dta < 60) {
    return `${Math.floor(dta)} minutos atrás`;
  } else {
    dta = dta / 60;

    if (Math.floor(dta) < 24) {
      return `${Math.floor(dta)} horas atrás`;
    } else {
      dta = dta / 24;
      return `${Math.floor(dta)} dias atrás`;
    }
  }
}

export function IsTodayDate(date) {
  let dta = Math.floor(Math.abs(new Date() - new Date(date)) / 1000 / 60);

  if (dta < 60) {
    // return Math.floor(dta); //minutos
    return true;
  } else {
    dta = dta / 60;

    if (Math.floor(dta) <= 12) {
      // return Math.floor(dta); //horas
      return true;
    } else {
      // dta = dta / 24;
      // return Math.floor(dta);//dias
      return false;
    }
  }
}

export function DateTranslate(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${day}/${month}/${year} ${hours}:${minutes}`;
  return formatted;
}

export function OnlyDateTranslate(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${day}/${month}`;
  return formatted;
}

export function DateTranslateSimple(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${day}/${month} ${hours}:${minutes}`;
  return formatted;
}

export function DateTranslateToInput(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${year}-${month}-${day}T${hours}:${minutes}`;
  return formatted;
}

export function DateTranslateToTimestamp(dte) {
  let s = dte;
  let [dia, mes, ano, hora, minuto] = s.split(/[\/: ]/).map((v) => parseInt(v));

  let data = new Date(ano, mes - 1, dia, hora, minuto);
  return DateTranslateToInput(data);
}

export function DateTimeNow() {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  console.log("Horas", hours, hours % 2);

  const formatted = `${year}-${month}-${day}T${
    hours < 10 ? `0${hours}` : hours
  }:${minutes}`;
  return formatted;
}

export function ProcessDates(list) {
  for (let s in list) {
    list[s].createdDate = DateTranslate(list[s].createdDate);
    list[s].updatedDate = DateTranslate(list[s].updatedDate);
  }
  return list;
}

export const ArrayMes = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

function getMesExtenso(mes) {
  return ArrayMes[mes];
}

export function GetMes() {
  const dataAtual = new Date();
  const mes = getMesExtenso(dataAtual.getMonth());

  return mes;
}

export function GetDia() {
  const dataAtual = new Date();
  const dia = dataAtual.getDate();

  return dia;
}

export const DayOfTheWeek = [
  {name: "Domingo", value : 0,},
  {name: "Segunda", value : 1,},
  {name: "Terça", value : 2,},
  {name: "Quarta", value : 3,},
  {name: "Quinta", value : 4,},
  {name: "Sexta", value : 5,},
  {name: "Sábado", value : 6,},
]

export const DayOfTheWeekObj = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
}

export function TranslateDay(i){
  const res = DayOfTheWeek.filter(x => x.value == i);
  if(res.length > 0){
    return res[0].name;
  }else{
    return "S.I.";
  }
}