const Storage = {
  isAuthenticated: function (data) {
    const userData = Storage.getUserData();
    if (userData) return true;
    return false;
  },
  setUserData: function (data) {
    localStorage.setItem("user_data", JSON.stringify(data));
  },
  setRegisterUserData: function (data) {
    localStorage.setItem("register_user_data", JSON.stringify(data));
  },
  getUserData: function () {
    const user = JSON.parse(localStorage.getItem("user_data"));
    return user !== null ? user : null;
  },
  getRegisterUserData: function () {
    const user = JSON.parse(localStorage.getItem("register_user_data"));
    return user !== null ? user : null;
  },
  getUserToken: function () {
    return JSON.parse(localStorage.getItem("user_data"));
  },
  getTheme: function () {
    return localStorage.getItem("theme");
  },
  setTheme: function (color) {
    localStorage.setItem("theme", color);
  },
  setCreditCardData: function (data) {
    localStorage.setItem("credit_card", JSON.stringify(data));
  },
  getCreditCardData: function () {
    const itens = JSON.parse(localStorage.getItem("credit_card"));
    if(itens == null){
      return [];
    }else{
      return itens;
    }
  },
  getFeatures: function () {
    const features = JSON.stringify(localStorage.getItem("features"));

    if (typeof features === "object") {
      return features;
    }
    return [];
  },
};

export default Storage;
