import React from "react";
import Logo from "@/assets/img/logo.png";

export default function GenericHeader({register}){

    function goPage(page){
        window.location = page;
    }

    function goHome(){
        window.location = `#/`;
    }

    return (
        <header id="page-header" className="bg-warning">
            <div className="content-header">
                <div onClick={()=> goHome()}>
                    <img src={Logo} style={{height : 58}} />
                </div>
                <div>
                    {register && register == true ? 
                    (<button
                        type="button"
                        className="btn btn-sm btn-white text-warning mr-1"
                        onClick={()=> goPage(`#/register`)}
                    >
                        Cadastrar
                    </button>)
                    :
                    (<button
                        type="button"
                        className="btn btn-sm btn-white text-warning mr-1"
                        onClick={()=> goPage(`#/auth`)}
                    >
                        Login
                    </button>)
                    }
                </div>
            </div>
            <div id="page-header-loader" className="overlay-header bg-primary-darker">
                <div className="content-header">
                <div className="w-100 text-center">
                    <i className="fa fa-fw fa-2x fa-sun fa-spin text-white" />
                </div>
                </div>
            </div>
        </header>
    )
}