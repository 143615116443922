import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { child, get, getDatabase, ref } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";
import UserCard from "@/app/core/layouts/main-components/users/UserCard";
import ArenaCard from "@/app/core/layouts/main-components/arena/ArenaCard";

export default function Coupons(props){
    const [data, setData] = useState([]);
    const [adms, setAdms] = useState([{}]);
    const [players, setPlayers] = useState([{}]);
    const [arenas, setArenas] = useState([{}]);

    const db = getDatabase(firebaseApp);

    useEffect(()=>{
        loadCoupons()
    },[]);

    function loadCoupons(){
        get(child(ref(db), `team`)).then((snapshot) => {
            let coupons = [];
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    coupons.push({
                        ...notYet[s],
                        id: s,
                    })
                }
                setData(coupons);
              }
          });
    }

      return (
        <div className="content row mt-3">
            <div className="col-md-6 row">
                <div class="col-2 text-right">
                    <img className="img-avatar img-avatar-thumb img-avatar-rounded border-gray border-2x border" src={"assets/media/avatars/avatar16.jpg"} />
                </div>
                <div class="col-8 p-3">
                    <span className="font-w700">
                        Liga Esporte Amador
                    </span>
                    <span className="font-w400">
                        Campo do batalha <br/>
                        Vargem grande paulista
                    </span>
                </div>
                <div className="col-2 text-right">
                    <button className="btn btn-dual mt-3">
                        <i className="fa fa-edit"/>
                    </button>
                </div>
                <div className="col-10">
                    <h2 className="content-heading">
                        <i className="fa fa-angle-right text-muted mr-1"></i>{" "} Responsável
                    </h2>
                </div>
                <div className="col-2 text-right">
                    <button className="btn btn-dual mt-3">
                        <i className="si si-plus text-warning"/>
                    </button>
                </div>
                <div className="col-md-12">
                    {adms && adms.map(item => (
                        <UserCard user={item} />
                    ))}
                </div>
            </div>
            <div className="col-md-6 row">
                <div className="col-10">
                    <h2 className="content-heading">
                        <i className="fa fa-angle-right text-muted mr-1"></i>{" "} Jogadores
                    </h2>
                </div>
                <div className="col-2 text-right">
                    <button className="btn btn-dual mt-3">
                        <i className="si si-plus text-warning"/>
                    </button>
                </div>
                <div className="col-md-12">
                    {players && players.map(item => (
                        <UserCard user={item} edit={true}/>
                    ))}
                </div>
            </div>
            <div className="col-md-6 row">
                <div className="col-10">
                    <h2 className="content-heading">
                        <i className="fa fa-angle-right text-muted mr-1"></i>{" "} Campo / Quadra
                    </h2>
                </div>
                <div className="col-2 text-right">
                    <button className="btn btn-dual mt-3">
                        <i className="si si-plus text-warning"/>
                    </button>
                </div>
                <div className="col-md-12">
                    {arenas && arenas.map(item => (
                        <ArenaCard user={item} />
                    ))}
                </div>
            </div>
        </div>
      )
}