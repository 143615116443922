import Storage from "@/app/core/util/Storage";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function Offers(props){
    const userData = Storage.getUserData();
    const [data, setData] = useState([
    ]);
    const [terms, setTerms] = useState([
        // {
        //     name: "Política de privacidade",
        //     uri: "",
        //     url: "https://google.com",
        //     updatedDate: "",
        //     createdDate: "",            
        // },
        // {
        //     name: "Política de privacidade",
        //     uri: "",
        //     url: "https://google.com",
        //     updatedDate: "",
        //     createdDate: "",
        // },
    ]);

    useEffect(()=>{
        loadPresentation()
    },[]);

    function loadPresentation(){
        if(userData != null){
            // setData([
            //     {
            //         id: 0,
            //         userId: "",
            //         term: {
            //             name: "Política de privacidade",
            //             uri: "",
            //             url: "https://google.com",
            //             updatedDate: "",
            //             createdDate: "",
            //         },
            //         updatedDate: "",
            //         createdDate: "",
                    
            //     },
            //     {
            //         id: 0,
            //         userId: "",
            //         term: {
            //             name: "Política de privacidade",
            //             uri: "",
            //             url: "https://google.com",
            //             updatedDate: "",
            //             createdDate: "",
            //         },
            //         updatedDate: "",
            //         createdDate: "",
                    
            //     }])
        }
    }

    function openLink(item){
        registerConversion(item)
        window.open(item.url, "_blank");
    }

    function registerConversion(){}


    async function handleChange(checked, item, index) {
        // CustomerResponseTerms(item.id, checked, (res) => loadConfigurations());
        let old = data;
        old[index].response = checked;
        setData(old);
      }
   

      return (
        <div className="content row mt-3">
            <div className="col-md-12">
                <h3 className="text-muted"><i className="fa fa-arrow-left mr-1"/> Termos de uso & políticas de privacidade</h3>
            </div>
            
            {data && data.map((item, index) => (
                <div className="col-md-12">
                    <div className="block block-bordered block-content">
                        <div className="form-group">
                            <div className="custom-control custom-checkbox custom-control-primary">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={"check-" + item.id}
                                    name={"check-" + item.id}
                                    checked={item.response}
                                    onChange={(evt) =>
                                    handleChange(evt.target.checked, item, index)
                                    }
                                />
                                <label
                                    className="custom-control-label"
                                    for={"check-" + item.id}
                                >
                                    Eu concordo com{" "}
                                    <a href={item.term.url} target="_blank">
                                    {item.term && item.term.name}
                                    </a>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {userData == null ? 
                <div className="block col-md-12 block-content">
                    {terms && terms.map(item => (
                        <div className="block block-bordered block-content">
                            <div className="form-group">
                                <div className="custom-control custom-checkbox custom-control-primary">
                                    <a href={item.url} target="_blank">
                                    {item && item.name}
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            :""}

            <div className="content">
                <p>
                    <br/>
                    <br/>
                Aceitação dos Termos:
                Ao baixar, instalar ou usar nosso aplicativo de viagem de motocicleta (o "AppMotos"), você concorda em ficar vinculado a estes Termos de Uso. Se você não concorda com estes termos, por favor, abstenha-se de usar o aplicativo.
                    <br/>
                    <br/>
                Uso do aplicativo:
                Nosso aplicativo AppMotos foi projetado para facilitar viagens em grupo seguras para entusiastas de motocicletas. Ele fornece recursos como planejamento de rotas, coordenação de viagens e ferramentas de comunicação para aprimorar sua experiência de viagem em grupo. O aplicativo é destinado apenas para uso pessoal e não comercial.
                    <br/>
                    <br/>
                Coleção de dados:
                Coletamos e armazenamos determinados dados para garantir o bom funcionamento do aplicativo. Isso inclui informações como tipo de dispositivo, sistema operacional e estatísticas de uso. No entanto, não capturamos nem armazenamos nenhuma informação de identificação pessoal para fins de marketing. Os dados coletados são usados exclusivamente para funcionalidade e melhoria do aplicativo.
                    <br/>
                    Dados que coletamos:
                    <br/>
                    <ul>
                        <li>Localização em segundo plano, precisa ou aproximada: Quando você compartilhar a sua localização com seus amigos em uma viagem, vamos consultar a sua posição apenas naquele instante. No momento que encerrar o prazo que você selecionou, encerrar a viagem ou sair do aplicativo, nós apagaremos esses dados.</li>
                        <li>Contatos de emergência: Você cadastrará os contatos de emergência no aplicativo e nós compartilhamos apenas se for reportado um problema de saúde ou segurança. Esses dados ficarão disponíveis para os administradores do passeio.</li>
                        <li>Informações básicas de perfil: Coletamos nome, email, foto de usuário. Essas informações são para identificá-lo. Também coletamos o telefone, mas apenas para casos de emergência.</li>
                    </ul>
                    <br/>
                Privacidade e segurança de dados:
                Nós levamos a sua privacidade a sério. Qualquer informação pessoal que você fornecer durante a criação da conta, como seu nome ou endereço de e-mail, será mantida em sigilo e não será compartilhada com terceiros sem o seu consentimento. Implementamos medidas de segurança padrão do setor para proteger seus dados contra acesso ou divulgação não autorizados.
                    <br/>
                    <br/>
                Responsabilidade do usuário:
                Você é responsável por manter a segurança das credenciais de login da sua conta e garantir a precisão das informações fornecidas no aplicativo. Você concorda em não usar o AppMotos de forma que viole quaisquer leis ou regulamentos aplicáveis ou infrinja os direitos de terceiros.
                    <br/>
                    <br/>
                Propriedade intelectual:
                Todos os direitos de propriedade intelectual relacionados ao aplicativo, incluindo, entre outros, marcas registradas, direitos autorais e informações proprietárias, pertencem a nós. Você não pode reproduzir, modificar, distribuir ou explorar qualquer parte do aplicativo sem o nosso consentimento prévio por escrito.
                    <br/>
                    <br/>
                Limitação de responsabilidade:
                Na extensão máxima permitida por lei, nos isentamos de qualquer responsabilidade por quaisquer danos, perdas ou ferimentos decorrentes ou relacionados ao uso do aplicativo. Não nos responsabilizamos por nenhuma ação, conduta ou conteúdo de outros usuários dentro do aplicativo.
                    <br/>
                    <br/>
                Modificação dos Termos:
                Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento. Iremos notificá-lo sobre quaisquer alterações materiais por meio do aplicativo ou por e-mail. Seu uso continuado do App após as modificações indica sua aceitação dos termos atualizados.
                    <br/>
                    <br/>
                Terminação:
                Podemos encerrar seu acesso ao App se você violar estes Termos de Uso ou se envolver em qualquer uso não autorizado ou inapropriado. Após a rescisão, todos os direitos e licenças concedidos a você cessarão e você deverá interromper o uso do AppMotos.
                    <br/>
                    <br/>
                Lei Aplicável:
                Estes Termos de Uso serão regidos e interpretados de acordo com as leis de [sua jurisdição]. Quaisquer disputas decorrentes do uso do aplicativo estarão sujeitas à jurisdição exclusiva dos tribunais em [sua jurisdição].

                </p>
            </div>
        </div>
      )
}