import React from "react";

export default function ArenaCard({arena, edit, remove, open}){

    return (
        <div className="block block-rounded block-bordered" key={`item`}>
            <div className="block-header block-header-default ">
                <div className="block-title">
                    {/* <img className="img-avatar img-avatar48 border-gray border-2x border" src={"assets/media/avatars/avatar16.jpg"} /> */}
                    <span className="ml-1">
                        Fabio Rodrigues
                    </span>

                </div>
                <div className="block-tools">
                    <button className="btn btn-sm btn-dual">
                        <i className="fa fa-edit text-muted"/>
                    </button>
                    <button className="btn btn-sm btn-dual">
                        <i className="fa fa-trash text-muted"/>
                    </button>
                </div>
            </div>
        </div>
    )
}