import React, { useRef } from "react";

export default function DocInput({
  multiple,
  uploadFile,
  disable,
  className,
  name,
  loading,
}) {
  const inputFile = useRef(null);

  function onUploadClick() {
    inputFile.current.click();
  }

  return (
    <>
      <button
        type="button"
        title="Fazer upload"
        className={"btn btn-hero-white text-warning"}
        disabled={disable}
        onClick={() => onUploadClick()}
      >
        {name}
        {" "}
        <i
          className={
            loading === true
              ? "fa fa-circle-notch fa-spin text-info mr-1"
              : "si si-cloud-upload mr-1"
          }
        />
      </button>
      <br />
      {multiple === true ? (
        <input
          type="file"
          multiple
          ref={inputFile}
          onChange={(event) => {
            uploadFile(event.currentTarget.files);
          }}
          style={{ visibility: "hidden", width: 10 }}
        />
      ) : (
        <input
          type="file"
          ref={inputFile}
          onChange={(event) => {
            uploadFile(event.currentTarget.files);
          }}
          style={{ visibility: "hidden", width: 10 }}
        />
      )}
    </>
  );
}
