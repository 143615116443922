import React from "react";
import { Link } from "react-router-dom";

export default function RecursiveMenu(data) {
  let itemRef = [];
  data = data.data ? data.data : data;

  function liClass(type) {
    switch (type) {
      case "over-text":
        return "nav-main-heading";
      case "single":
        return "nav-main-link active";
      default:
        return "nav-main-item";
    }
  }

  function submenuClass(children, type) {
    let strClass = children ? "nav-main-link-submenu" : "";
    if (type === "single") strClass += " active";
    return strClass;
  }

  const submenu = (data) => {
    return data.map((i, idx) => {
      const listIndex = `${idx}${Math.floor(Math.random() * 90 + 10)}`;
      itemRef[listIndex] = React.createRef();
      return (
        <li
          key={idx}
          className={liClass(i.type)}
          ref={(el) => (itemRef[listIndex] = el)}
        >
          {!i.children && i.url === undefined ? (
            <Link
              className={`nav-main-link ${submenuClass(i.children, i.type)}`}
              to={i.routerLink || ""}
            >
              <i
                className={`nav-main-link-icon ${i.icon ? "si " + i.icon : ""}`}
              />
              <span className="nav-main-link-name">{i.name}</span>
              <br />
              {i.badge && (
                <span className="nav-main-link-badge badge badge-pill badge-secondary">
                  {i.badge}
                </span>
              )}
            </Link>
          ):
           ""
          
          }
          {i.children && (
            <span
              style={{ cursor: "pointer" }}
              className={`nav-main-link ${submenuClass(i.children, i.type)}`}
              onClick={() => itemRef[listIndex].classList.toggle("open")}
            >
              <i
                className={`nav-main-link-icon ${i.icon ? "si " + i.icon : ""}`}
              />
              <span className="nav-main-link-name">{i.name}</span>
              <br />
              {i.badge && (
                <span className="nav-main-link-badge badge badge-pill badge-secondary">
                  {i.badge}
                </span>
              )}
            </span>
          )}
          {i.children && (
            <ul className="nav-main-submenu">{submenu(i.children)}</ul>
          )}
        </li>
      );
    });
  };
  return <>{submenu(data)}</>;
}
