import React from "react";
import { useEffect, useState, useRef } from "react";
import { child, get, getDatabase, push, ref, remove } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";
import { getDownloadURL, getStorage, ref as refStor, uploadBytesResumable } from "firebase/storage";
import { Panel, Tab, Tabs } from "@bumaga/tabs";
import UserStep from "../../newAccount/main/components/UserStep";
import UserPosts from "@/app/core/layouts/main-components/users/UserPosts";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function Profile(props){
    const [data, setData] = useState(Storage.getUserData());
    const [picture, setPicutre] = useState({});
    const inputFile = useRef(null);
    const db = getDatabase(firebaseApp);

    useEffect(()=>{
        loadAccount()
    },[]);

    function onUploadClick() {
        inputFile.current.click();
      }
    
    function loadAccount(){
        get(child(ref(db), `users/${data.id}`)).then((snapshot) => {
            if (snapshot.exists()) {
                const infos = {...snapshot.val(), id: data.id};
                console.log("account", infos)
                setData(infos);
                if(infos.avatarPictures != undefined){
                    let res = {}
                    for(let s in infos.avatarPictures){
                        res = {...infos.avatarPictures[s], id: s};
                    }
                    setPicutre(res);
                    console.log("fotos", res);
                }
            }
        });
    }

    function saveAccount(){
        setTimeout(()=>{
            toast.success(Message.Saved);
        },450)
    }

    function removePicture(){
        if(data.id != undefined){
            if(window.confirm(Message.DeleteConfirm)){
                remove(ref(db, `users/${data.id}/avatarPictures/${picture.id}`));
                loadAccount();
            }
        }
    }

    function uploadPicture(files){
        if(data.id != undefined){

            const storage = getStorage();
            const reference = refStor(storage, `${data.id}`);
    
            // 'file' comes from the Blob or File API
            const uploadTask = uploadBytesResumable(reference, files[0]);
            uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                    console.log('Upload is paused');
                    break;
                    case 'running':
                    console.log('Upload is running');
                    break;
                }
                },
                (error) => {
                // Handle unsuccessful uploads
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        push(ref(db, `users/${data.id}/avatarPictures`), {
                            url: downloadURL,
                            name: "Sem nome",
                        });
                        loadAccount();
                    });
                }
            )
        }
    }

      return (
        <div className="content row mt-3">
            <div className="col-md-6 row" style={{height: 180}}>
                <div class="col-12 text-center">
                    <img className="img-avatar img-avatar-thumb border-gray border-2x border" src={picture && picture.url != undefined ? picture.url : "assets/media/avatars/avatar16.jpg"} 
                        onClick={() => onUploadClick()}/>
                    <br/>
                    <button className="btn btn-dual btn-sm" onClick={()=> removePicture()}><i className="si si-close"/></button>
                </div>
                <div class="col-12 p-3  text-center">
                    <span className="font-w700">
                        {data.firstName}
                        {data.lastName}
                    </span>
                    <br/>
                    <span className="font-w400">
                        {data.email}
                    </span>
                </div>
            </div>
            <div className="col-md-6 row">
                <Tabs>
                    <div className="col-md-12 text-center">
                        <Tab>
                            <button className="btn btn-dual">
                                Perfil
                            </button>
                        </Tab>
                        <Tab>
                            <button className="btn btn-dual">
                                Conta
                            </button>
                        </Tab>
                    </div>
                    <Panel>
                        <div className="block block-fx-pop col-md-12">
                            <UserPosts userId={data.id} edit={true}/>
                        </div>
                    </Panel>
                    <Panel>
                        <div className="block block-fx-pop col-md-12">
                            <UserStep data={data} update={true}/>
                            <button className="btn btn-block btn-hero-lg btn-hero-warning" onClick={()=> saveAccount()}>
                                Salvar alterações
                            </button>
                        </div>
                    </Panel>
                </Tabs>
            </div>
            <input
                type="file"
                ref={inputFile}
                onChange={(event) => {
                    uploadPicture(event.currentTarget.files);
                }}
                style={{ visibility: "hidden", width: 10 }}
                />
        </div>
      )
}