import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { DashboardRouter } from "@/app/modules/dashboard";
import AuthenticationRouter from "@/app/modules/auth";
import PostRouter from "@/app/modules/post";
import Error404Page from "@/app/core/layouts/errors/Error404Page";
import newAccount from "./modules/newAccount";
import RecoverRouter from "./modules/recoverPassword";
import ResetRouter from "./modules/resetPassword";
import OffersRouter from "./modules/offers";
import CouponsRouter from "./modules/coupons";
import TermsRouter from "./modules/terms";
import NewGameRouter from "./modules/newGame";
import SearchRouter from "./modules/search";
import ContactRouter from "./modules/contact";
import MyTeamRouter from "./modules/myteam";
import ProfileRouter from "./modules/profile";

export default function App() {
  if (!localStorage.getItem("theme")) {
    localStorage.setItem("theme", "light");
  }

  document.body.classList.add("bg-white");

  const routes = [
    newAccount,
    AuthenticationRouter,
    PostRouter,
    ResetRouter,
    RecoverRouter,
    OffersRouter,
    ProfileRouter,
    MyTeamRouter,
    ContactRouter,
    CouponsRouter,
    TermsRouter,
    NewGameRouter,
    DashboardRouter,
    SearchRouter,
    {
      path: "*",
      component: Error404Page,
      onShell: false,
    },
  ];

  return <Router>{renderRoutes(routes)}</Router>;
}
