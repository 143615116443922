import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import SportStep from "./SportStep";
import handleChange from "@/app/core/util/helpers/HandleChange";
import UserStep from "./UserStep";
import CheckPhoneStep from "./CheckPhoneStep";
import TeamCategoryStep from "./TeamCategoryStep";
import TeamDataStep from "./TeamDataStep";
import ArenaPictureStep from "./ArenaPictureStep";
import InviteStep from "./InviteStep";
import SportsController from "@/app/core/base/controllers/SportsController";
import { getDatabase, get, ref, child, push, Database, set, update } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";
import Storage from "@/app/core/util/Storage";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { CheckUserData } from "@/app/core/util/helpers/UserHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";
import Axios from "axios";
import { TeamTypeObj } from "@/app/core/util/helpers/TeamHelper";
import { getDownloadURL, getStorage, ref as storageRef, uploadBytesResumable } from "firebase/storage";

export default function TeamRegister({goBack}) {
    const sportController = SportsController();
    const db = getDatabase(firebaseApp);
    const auth = getAuth();
    const storage = getStorage();
    const [step, setStep] = useState(1);
    const [data, setData] = useState({user:{}, team: {schedule:[], reviews: 5, totalReviews: 0}, sport: {}});
    const [current, setCurrent] = useState(0);
    const [sports, setSports] = useState([
        {name: "Futebol", id: 1}
    ]);
    const [positions, setPositions] = useState([
        {name: "Administrador", id: 1}
    ]);
    const [gameModule, setGameModule] = useState([
        {name: "Society", id: 1, players: 11}
    ]);
    const [ageGroups, setAgeGroups] = useState([
        {name: "Veterano para atletas a partir dos 35 anos", id: 1, players: 11}
    ]);
    const [fieldTypes, setFieldTypes] = useState([
        {name: "Campo grama natural", id: 1, players: 11}
    ]);
    const [position, setPosition] = useState([]);

    useEffect(()=>{
      loadInfos();
      loadAgeGroups();
      const base = Storage.getRegisterUserData();
      if(base != null && base.user && base.user.firstName != undefined){
        setData(base);
        if(base.user.id !== undefined){
          if(base.user.confirmationCode !== undefined){
            setStep(4);
          }if(base.team.logo && base.team.logo.url != undefined){
            setStep(6);
          }else{
            setStep(3);
          }
          if(base && base.sport && base.sport.sportId != undefined){
            loadGameModule(base.sport.sportId);
            loadPositions(base.sport.sportId);
            loadFieldType(base.sport.sportId);
          }
        }
      }
      console.log("usuário", base, data);
    },[])
    
    function preSave(){
      if(data.user != undefined && data.user.firstName !== undefined){
        Storage.setRegisterUserData(data);
      }
    }

    useEffect(()=>{
      preSave();
      console.log("mudou", data);
    },[data])

    
    async function loadInfos(){
      // sportController.Insert();
      get(child(ref(db), `sports`)).then((snapshot) => {
          let sportsData = [];
          if (snapshot.exists()) {
              const notYet = snapshot.val();
              for (let s in notYet) {
                  sportsData.push({
                      ...notYet[s],
                      id: s,
                  })
              }
              setSports(sportsData);
          }
      });
    }
    
    async function loadAgeGroups(){
      get(child(ref(db), `ageGroup`)).then((snapshot) => {
          let age = [];
          if (snapshot.exists()) {
              const notYet = snapshot.val();
              for (let s in notYet) {
                  age.push({
                      ...notYet[s],
                      id: s,
                  })
              }
              setAgeGroups(age);
          }
      });
    }

    async function nextStep(){
        setStep(step + 1);
    }

    function returnStep(){
        setStep(step - 1);
    }

    function handleSportChange(evt, type){
        setData({...data, sport: handleChange(data.sport, evt, type)});
        console.log("Dados", data);
        if(evt.target.name === "sportId"){
          loadGameModule(evt.target.value);
          loadPositions(evt.target.value);
          loadFieldType(evt.target.value);
        }
      }

    function loadGameModule(id){
      get(child(ref(db), `gameModule/${id}`)).then((snapshot) => {
        let module = [];
        if (snapshot.exists()) {
            const notYet = snapshot.val();
            for (let s in notYet) {
                module.push({
                    ...notYet[s],
                    id: s,
                })
            }
            setGameModule(module);
        }
      });
    }
    
    function loadPositions(id){
      get(child(ref(db), `position/${id}`)).then((snapshot) => {
        let posi = [];
        if (snapshot.exists()) {
            const notYet = snapshot.val();
            for (let s in notYet) {
                posi.push({
                    ...notYet[s],
                    id: s,
                })
            }
            setPositions(posi);
        }
      });
    }
    
    function loadFieldType(id){
      get(child(ref(db), `fieldType/${id}`)).then((snapshot) => {
        let fields = [];
        if (snapshot.exists()) {
            const notYet = snapshot.val();
            for (let s in notYet) {
                fields.push({
                    ...notYet[s],
                    id: s,
                })
            }
            setFieldTypes(fields);
        }
      });
    }

    function handleTeamChange(evt, type){
        setData({...data, team: handleChange(data.team, evt, type)});
        if(evt.target.name === "cep" && evt.target.value.length >= 8){
          getAddress(evt.target.value);
        }
        console.log("Dados", data);
    }

    
  async function getAddress(cep) {
    Axios.get(`https://viacep.com.br/ws/${cep}/json`, {})
      .then(function (response) {
        let location = "";
        if (response.data.logradouro) {
          location += `${response.data.logradouro} `;
        }
        if (response.data.bairro) {
          location += `${response.data.bairro} `;
        }
        if (response.data.localidade) {
          location += `${response.data.localidade} `;
        }
        let infos = { ...data, 
            team:{...data.team, 
                address: location, 
                cep: cep, neighborhood: response.data.bairro, 
                city: response.data.localidade, 
                street: response.data.logradouro}
              };
        setData(infos);
      })
      .catch(function (error) {
        if (error) {
        }
      });
  }

    function handleUserChange(evt, type){
        setData({...data, user: handleChange(data.user, evt, type)});
        console.log("Dados", data);
    }

    function returnChoices(){
        goBack()
    }

    function addGameTime(item){
      let schedule = data.team.schedule;
      schedule.push(item);
      setData({...data, team: {...data.team,schedule}})
    }

    function removeGameTime(item, index){
      let schedule = data.schedule;
      setData({...data, team:{...data.team, schedule: schedule.slice(index, 0)}})
    }
    
    function endRegister(){
      toast.success("Time cadastrado com sucesso!");
      Storage.setUserData({...data.user, team: {...data.team}});
      setTimeout(()=>{
        window.location = "#/";
      },450)
    }


    async function registerUser(){
      if(data.user.terms != true){
        toast.error(Message.Account.TermsNeeded);
      }else{
        const checkAccount = CheckUserData(data.user);
        
        if(checkAccount.error === true){
          console.log("Faltou mesmo", data, checkAccount);
          toast.error(checkAccount.message);
        }else{
          await createUserWithEmailAndPassword(auth, data.user.email, data.user.password)
          .then(async (userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log("Conta");
            await set(ref(db, `users/${user.uid}`), {
                ...data.user
            });
            await set(ref(db, `userSport/${user.uid}`), {
                ...data.sport
            });
            setData({...data, user: {...data.user, id: user.uid}, team: {...data.team, users: [{role: 1, userId: user.uid}]}});
            setStep(step + 1);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            toast.error(errorMessage);
          });
        }
      }
    }

    function nextTeamStep(){
      if(data && data.team && data.team.type === TeamTypeObj.Visitor){
        setStep(7);
      }else{
        setStep(step + 1);
      }
    }

    function registerTeam(){
      if(data.team.cep != undefined && data.team.cep != ""){

        if(data.team.id != undefined){
          console.log("Update", data);
          update(ref(db, `teams/${data.team.cep}`), {
              ...data.team
          });
          if(data.team && data.team.files && data.team.files.length > 0){
            uploadFile(data.team);
          }else{
            setData({...data, team: {...data.team, id: data.team.cep, files: []}});
            setStep(step +1);
          }
        }else{
          set(ref(db, `teams/${data.team.cep}`), {
              ...data.team
          });
          if(data.team && data.team.files && data.team.files.length > 0){
            uploadFile(data.team);
          }else{
            setData({...data, team: {...data.team, id: data.team.cep}});
            setStep(step +1);
          }
        }
      }else{
        toast.error("CEP é obrigatório")
      }
    }


    function uploadFile(team){
      const storage = getStorage();
      const reference = storageRef(storage, `${team.cep}`);

      // 'file' comes from the Blob or File API
      const uploadTask = uploadBytesResumable(reference, data.team.files[0]);
      uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            update(ref(db, `teams/${data.team.cep}`), {
              logo: {url: downloadURL}
            });

            setData({...data, team: {...data.team, logo: {url: downloadURL}, files:[]}})
            setStep(step +1);
          });
        }
      )
    }

    function prepareFiles(files){
      console.log("files", files);
      let old = data.team;
      old.files = files;
      setData({...data, team: old})
    }

  function changeStep() {
    switch (step) {
      case 1:
        return (
          <div>
            <SportStep
              nextStep={() => nextStep()}
              prevStep={() => returnChoices()}
              handleChange={(evt, type) => handleSportChange(evt, type)}
              sports={sports}
              modules={gameModule && gameModule}
              values={data}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <UserStep
                nextStep={() => registerUser()}
                prevStep={() => returnStep()}
                handleChange={(evt, type) => handleUserChange(evt, type)}
                data={data && data.user}
                positions={positions && positions}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <CheckPhoneStep
                nextStep={() => nextStep()}
                prevStep={() => returnStep()}
                handleChange={(evt, type) => handleUserChange(evt, type)}
                data={data && data.user}
            />
          </div>
        );
      case 4:
        return (
          <div>
            <TeamCategoryStep
                nextStep={() => nextTeamStep()}
                prevStep={() => returnStep()}
                handleChange={(evt, type) => handleTeamChange(evt, type)}
                data={data && data.team}
                categories={ageGroups}
                fieldTypes={fieldTypes}
                uploadFile={files => prepareFiles(files)}
            />
          </div>
        );
      case 5:
        return (
          <div>
            <TeamDataStep
                nextStep={() => registerTeam()}
                prevStep={() => returnStep()}
                handleChange={(evt, type) => handleTeamChange(evt, type)}
                data={data && data.team && data.team.schedule}
                addSchedule={(item) => addGameTime(item)}
                removeSchedule={(item, index) => removeGameTime(item)}
            />
          </div>
        );
      case 6:
        return (
          <div>
            <ArenaPictureStep
                nextStep={() => nextStep()}
                prevStep={() => returnStep()}
                data={data && data.team}
                handleChange={(evt, type) => handleTeamChange(evt, type)}
            />
          </div>
        );
      case 7:
        return (
          <div>
            <InviteStep
                endRegister={() => endRegister()}
                prevStep={() => returnStep()}
                data={data && data.team}
                handleChange={(evt, type) => handleTeamChange(evt, type)}
            />
          </div>
        );
      default:
        return (
          <div>
            <CheckPhoneStep
                nextStep={() => nextStep()}
                prevStep={() => returnStep()}
                handleChange={(evt, type) => handleUserChange(evt, type)}
                data={data && data.user}
            />
          </div>
        );
    }
  }

    return (
        <div className="col-sm-8 col-xl-8">
            {step && changeStep()}
        </div>
    )
}