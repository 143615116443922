import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";
import CheckMobile from "@/app/core/util/Screen";
import Logo from "@/assets/img/logo.png";

export default function Header(props) {
  const userData = Storage.getUserData();
  let screenWidth = CheckMobile();

  useEffect(() => {
  }, [userData]);

  function goHome(){
    window.location = `#/`;
  }

  function newGame(){
    window.location = `#/newGame`;
  }

  function leave() {
    if (window.confirm("Tem certeza que deseja sair?")) {
      localStorage.removeItem("user_data");
      window.location.reload();
    }
  }


  function loadTopButtons() {
    if (userData !== null) {
      return (
        <div className="btn-group" role="group" aria-label="Horizontal Secondary">
          {/* <button
            type="button"
            className="btn btn-sm btn-success text-white"
            onClick={() => newGame()}
          >
            <i className="fa fa-fw fa-search"/>
          </button> */}
          {/* <button
              type="button"
              className="btn btn-sm btn-success"
              alt="Sair"
              onClick={() => leave()}
            >
              <i className="si si-logout"></i>
            </button> */}
        </div>
      );
    }
  }

  return (
    <header id="page-header" className="bg-warning">
        {screenWidth == false ? 
          <div className="content-header">
            <div onClick={()=> goHome()}>
              {/* <button
              type="button"
              className="btn btn-success mr-1 text-white"
              onClick={() => props.toggleNav()}
            >
              <i className="fa fa-fw fa-bars" />
            </button> */}
              <img src={Logo} style={{height : 55}}/>
            </div>
            <div>
              {loadTopButtons()}
            </div>
          </div>
        :
          <div className="row p-2">
            <div className="col-4" onClick={()=> goHome()}>
              <img src={Logo} style={{height : 58}}/>
            </div>
            <div className="col-8 text-right">
                {loadTopButtons()}
            </div>
          </div>
        }

      <div id="page-header-loader" className="overlay-header bg-primary-darker">
        <div className="content-header">
          <div className="w-100 text-center">
            <i className="fa fa-fw fa-2x fa-sun fa-spin text-white" />
          </div>
        </div>
      </div>
    </header>
  );
}
