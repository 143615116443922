import React from "react";
import "../assets/footer.css";

export default function Footer() {
  return (
    <footer
      id="page-footer"
      className={`bg-body-light text-muted py-3`}
    >
      <div className="content py-0">
        <div className="row font-size-sm">
          <div className="col-sm-6 order-sm-1 text-center text-sm-left">
            Copyright &copy; <span data-toggle="year-copy">{new Date().getFullYear()}</span> AppMotos
            <br/>
            Todos os direitos reservados
          </div>
          <div className="col-sm-6 order-sm-1 text-center text-sm-right">
            <div>
              <a className="btn btn-dual" href="/#">
                <i className="fab fa-instagram"/>
              </a>
              <a className="btn btn-dual" href="/#">
                <i className="fab fa-facebook-f"/>
              </a>
              <a className="btn btn-dual" href="/#">
                <i className="fab fa-youtube"/>
              </a>
            </div>
            versão 0.1.1
          </div>
        </div>
      </div>
    </footer>
  );
}
