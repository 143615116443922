// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqdl3pmHSSz5YA1RVju_Rx33i-sWvoei4",
  authDomain: "liga-683ae.firebaseapp.com",
  databaseURL: "https://liga-683ae-default-rtdb.firebaseio.com",
  projectId: "liga-683ae",
  storageBucket: "liga-683ae.appspot.com",
  messagingSenderId: "312582969635",
  appId: "1:312582969635:web:bc2552a1d1f9f7ced45496",
  measurementId: "G-X3KH0YGWQ0"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);