import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { CheckCreditCard } from "@/app/core/util/helpers/MoneyHelper";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import Storage from "@/app/core/util/Storage";

export default function CreditCardModal({ show, onHide, isLoading }) {
  const [data, setData] = useState({});
  const [cards, setCards] = useState(Storage.getCreditCardData());

  useEffect(() => {}, [show]);

  function onSubmit(e) {
    e.preventDefault();
    save();
  }

  function handleChange(evt) {
    setData({...data, [evt.target.name]: evt.target.value});
  }

  async function save() {
    if (checkFields()) {
      if (!CheckCreditCard(data.number)) {
        // toast.error("Número do cartão é inválido");
        // return;
      }

      Storage.setCreditCardData([data]);
      
      toast.success("Salvo com sucesso");
      onHide();
    } else {
      toast.error("Preencha todos os dados para seguir em frente");
    }
  }

  function checkFields() {
    let res = true;

    if (data.number === undefined || data.number === "") {
      res = false;
    }
    if (data.name === undefined || data.name === "") {
      res = false;
    }
    if (data.venc === undefined || data.venc === "") {
      res = false;
    }
    if (data.cvv === undefined || data.cvv === "") {
      res = false;
    }
    return res;
  }

  return (
    <Modal
      title="Cartão de crédito"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSubmit={() => save()}
    >
      <form className="js-validation-signin" onSubmit={(e) => onSubmit(e)}>
        <div className="form-group text-center py-3 row">
            <div className="col-3"></div>
            <div className="col-6">

                <img src="assets/media/credit-card.png" />
                <div
                    style={{
                    marginTop: -150,
                    padding: 80,
                    }}
                >
                    <div className="row text-white font-size-sm">
                    <div className="col-12 text-left">
                        {data.number !== "" ? data.number : "0000 0000 0000 0000"}
                    </div>
                    <div className="col-8 text-left">
                        {data.name !== "" ? data.name : "Nome S. Sobrenome"}
                    </div>
                    <div className="col-4 text-right">
                        {data.venc !== "" ? data.venc : "MM/AA"}
                    </div>
                    </div>
                </div>
            </div>
            <div className="col-3"></div>
        </div>
        <div className="form-group text-center">
          <InputMask
            mask="9999 9999 9999 9999"
            maskChar=""
            type="text"
            className="form-control"
            id="advanced-filter-document"
            name="number"
            placeholder="Digite o número do cartão"
            onChange={(event) => {
              handleChange({
                target: {
                  name: "number",
                  value: event.target.value.replace(/\D/g, ""),
                },
              });
            }}
          />
        </div>
        <div className="form-group text-center">
          <input
            type={"text"}
            className="form-control"
            name="name"
            placeholder="Nome escrito no cartão"
            onChange={(evt) => handleChange(evt)}
          />
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group text-center">
              <InputMask
                mask="99/99"
                maskChar=""
                type="text"
                className="form-control"
                id="advanced-filter-document"
                name="venc"
                placeholder="Vencimento"
                onChange={(event) => {
                  handleChange({
                    target: {
                      name: "venc",
                      value: event.target.value.replace(/\D/g, ""),
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group text-center">
              <InputMask
                mask="999"
                maskChar=""
                type="text"
                className="form-control"
                id="advanced-filter-document"
                name="cvv"
                placeholder="CVV"
                onChange={(event) => {
                  handleChange({
                    target: {
                      name: "cvv",
                      value: event.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group text-center">
                <span>Tipo de cartão</span>
                <select className="form-control">
                  <option>Cartão de crédito</option>
                  <option>Cartão de débito</option>
                </select>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
