import React, { useState, useEffect } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import Storage from "@/app/core/util/Storage";
import { ToastContainer, toast } from "react-toastify";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import NewsPanel from "@/app/core/layouts/main-components/Inbound/NewsPanel";
import SportsController from "@/app/core/base/controllers/SportsController";
import { child, get, getDatabase, ref } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";
import HomeComponent from "../HomeComponent";
import CreditCardModal from "@/app/core/layouts/main-components/charge/CreditCardModal";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function MainDashboard() {
  const userData = Storage.getUserData();
  const db = getDatabase(firebaseApp);
  const [loading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([
    {id: 1, name: "Futebol"}
  ]);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    loadCards()
  }, []);

  function loadCards(){
    console.log("Cartão de ", Storage.getCreditCardData());
    setCards(Storage.getCreditCardData());
  }

  function removeCard(index){
    if(window.confirm(Message.DeleteConfirm)){
      let old = cards;
      let newOld = old.splice(index, 1);
      console.log("After", newOld, old);
      Storage.setCreditCardData(old);
      setTimeout(()=>{
        loadCards();
      },400)
    }
  }

  return (
    <>
      <Loading loading={loading} />
      <div className={Storage.getTheme()}>
        <div className="content">
          <Tabs>
            <div className="row">
              <div className="col-md-2 border-right">
                <div class="block block-rounded block-link-shadow text-center">
                  <div class="block-content block-content-full bg-body-light">
                      <img class="img-avatar" src="assets/media/avatars/avatar7.jpg"/>
                  </div>
                  <div class="block-content block-content-full">
                      <p class="font-size-sm font-italic text-muted mb-0">
                          Olá
                      </p>
                      <p class="font-w600 mb-0">Conta teste</p>
                  </div>
                </div>
                <h2 className="content-heading">
                  <i className="fa fa-angle-right text-muted mr-1"></i>{" "} Geral
                </h2>
                <div className="py-1">
                  <Tab>
                    <button className="btn btn-hero btn-dual ml-1 mr-1">
                      <i className="si si-home mr-2"/>
                      Home
                    </button>
                  </Tab>
                </div>
                <div className="py-1">
                  <Tab>
                    <button className="btn btn-hero btn-dual ml-1 mr-1">
                      <i className="si si-notebook mr-2"/>
                      Cotação
                    </button>
                  </Tab>
                </div>
                <div className="py-1">
                  <Tab>
                    <button className="btn btn-hero btn-dual ml-1 mr-1">
                      <i className="si si-pin mr-2"/>
                      Rastreamento
                    </button>
                  </Tab>
                </div>
                <div className="py-1">
                  <Tab>
                    <button className="btn btn-hero btn-dual ml-1 mr-1">
                      <i className="far fa-file mr-2"/>
                      Documentos
                    </button>
                  </Tab>
                </div>
                
                <div className="py-1">
                  <Tab>
                    <button className="btn btn-hero btn-dual ml-1 mr-1">
                      <i className="si si-globe-alt mr-2"/>
                      Meus pedidos
                    </button>
                  </Tab>
                </div>
                
                <div className="py-1">
                  <Tab>
                    <button className="btn btn-hero btn-dual ml-1 mr-1">
                      <i className="si si-wallet mr-2"/>
                      Cobrança
                    </button>
                  </Tab>
                </div>
                
                <div className="py-1">
                  <Tab>
                    <button className="btn btn-hero btn-dual ml-1 mr-1">
                      <i className="si si-user mr-2"/>
                      Perfil
                    </button>
                  </Tab>
                </div>
                <h2 className="content-heading">
                  <i className="fa fa-angle-right text-muted mr-1"></i>{" "} Suporte e Ajuda
                </h2>
                <div className="py-1">
                  <Tab>
                    <button className="btn btn-hero btn-dual ml-1 mr-1">
                      <i className="si si-question mr-2"/>
                      Ajuda
                    </button>
                  </Tab>
                </div>
                <div className="py-1">
                  <Tab>
                    <button className="btn btn-hero btn-dual ml-1 mr-1">
                      <i className="si si-support mr-2"/>
                      Suporte
                    </button>
                  </Tab>
                </div>
                <div className="py-1">
                  <Tab>
                    <button className="btn btn-hero btn-dual ml-1 mr-1">
                      <i className="si si-list mr-2"/>
                      Termos
                    </button>
                  </Tab>
                </div>
              </div>
              <div className="col-md-10">
                <Panel>
                  <HomeComponent/>
                </Panel>
                <Panel>
                  <HomeComponent/>
                </Panel>
                <Panel>
                  <HomeComponent/>
                </Panel>
                <Panel>
                  <HomeComponent/>
                </Panel>
                <Panel>
                  <HomeComponent/>
                </Panel>
                <Panel>
                  <div className="row">
                    <div className="col-md-8">
                      <h2 className="content-heading font-w700">Cobrança</h2>
                    </div>
                    <div className="col-md-4 text-right">
                      <button className="btn btn-success" onClick={()=> setShowModal(true)}>
                        <i className="si si-plus"/> Adicionar cartão
                      </button>
                    </div>
                    <div className="col-md-12">
                      <table className="table table-hover table-striped">
                        <thead>
                          <tr>
                            <th>Nº do cartão</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cards &&
                            cards.map((item, index) => (
                              <tr>
                                <td>{item.venc}</td>
                                <td>
                                  <button className="btn btn-dual btn-sm" onClick={()=> removeCard(index)}>
                                    <i className="si si-trash text-danger" /> Remover cartão
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {cards.length === 0 ? (
                        <div className="col-12 text-center">
                          <h4 className="font-w700 text-dark">Nenhum cartão adicionado</h4>
                          <a
                            className="btn btn-info"
                            href="javascript:void(0)"
                            onClick={() => setShowModal(true)}
                          >
                            Adicionar cartão
                          </a>
                          <hr style={{ width: 60 }} />
                        </div>
                      ) : (
                        ""
                      )}

                    </div>
                    <div className="col-md-12">
                      <h2 className="content-heading font-w700">Faturas</h2>
                    </div>
                    <div className="col-md-12">
                      <table className="table table-hover table-striped">
                        <thead>
                          <tr>
                            <th>Data da fatura</th>
                            <th>Período de cobrança</th>
                            <th>Valor devido</th>
                            <th>Valor total</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                      </table>
                        <div className="col-12 text-center">
                          <h4 className="font-w700 text-dark">Opção de faturamento desabilitado</h4>
                          <p className="font-w700 text-dark">Entre em contato com a BeeMove para ativar</p>
                          <hr style={{ width: 60 }} />
                        </div>
                    </div>
                  </div>
                </Panel>
                <Panel>
                  <HomeComponent/>
                </Panel>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
      <ToastContainer className="toastr" />
      <CreditCardModal
        show={showModal}
        onHide={()=>setShowModal(false) | loadCards()}
      />
    </>
  );
}
