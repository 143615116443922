import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { GenderType, PasswordIsStrong } from "@/app/core/util/helpers/UserHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import InputMask from "react-input-mask";
import React, { useState } from "react";
import { toast } from "react-toastify";

export default function UserStep({handleChange, nextStep, prevStep, data, positions, base, update}) {
    const [fieldStatus, setFieldStatus] = useState("");
    const [visible, setVisible] = useState(false);

    function handlePasswordChange(evt){
        if(evt.target.name == "passwordConfirm" && data.password){
            if(data.password.length <= evt.target.value.length){
                if(data.password != evt.target.value){
                    toast.error(Message.Account.PasswordDiferent);
                }
            }
        }
        if (evt.target.value.length > 5) {
            if (!PasswordIsStrong(evt.target.value)) {
              setFieldStatus(" is-invalid");
            } else {
              setFieldStatus(" is-valid");
            }
          }
          handleChange(evt);
    }

    return (
        <div>
            {update == undefined ? 
                <p className="text-uppercase font-w700 font-size-sm">
                    Seu esporte é FUTEBOL, agora vamos iniciar o cadastro do time.
                </p>
            :""}
            <div className="form-group">
              <span>Nome</span>
              <input type="text" name="firstName" value={data && data.firstName} className="form-control" onChange={evt => handleChange(evt)}/>
            </div>
            <div className="form-group">
              <span>Sobrenome</span>
              <input type="text" name="lastName" value={data && data.lastName} className="form-control" onChange={evt => handleChange(evt)}/>
            </div>
            <div className="form-group">
              <span>Celular</span>
              <InputMask
                mask="(99) 99999-9999"
                maskChar=""
                type="text"
                className="form-control"
                id="advanced-filter-document"
                name="phone"
                placeholder="(99) 99999-9999"
                value={data && data.phone}
                onChange={(event) => {
                    handleChange({
                        target: {
                        name: "phone",
                        value: event.target.value.replace(/\D/g, ""),
                        },
                    });
                }}/>
            </div>
            <div className="form-group">
              <span>CPF</span>
              <InputMask
                mask="999.999.999-99"
                maskChar=""
                type="text"
                className="form-control"
                id="advanced-filter-document"
                name="cpf"
                placeholder="999.999.999-99"
                value={data && data.cpf}
                onChange={(event) => {
                handleChange({
                    target: {
                    name: "cpf",
                    value: event.target.value.replace(/\D/g, ""),
                    },
                });
                }}
                />
            </div>
            
            <div className="form-group">
              <span>Data de nascimento</span>
              <input type="date" name="dateOfBirth" value={data && data.dateOfBirth} className="form-control" onChange={evt => handleChange(evt)}/>
            </div>

            <div className="form-group">
              <span>Sexo</span>
              <select className="form-control" value={data && data.gender} name="gender" onChange={evt => handleChange(evt, FieldTypeObj.Number)}>
                <option value={null}>Selecione</option>
                {GenderType.map(item => (
                    <option value={item.value}>{item.name}</option>
                ))}
              </select>
            </div>
            {base == true ?"":
                <div className="form-group">
                    <span>Posição no time</span>
                    <select className="form-control" name="position">
                        <option value={null}>Selecione</option>
                        {positions && positions.map(item => (
                            <option value={item.id}>{item.name}</option>
                        ))}
                    </select>
                </div>
            }
            
            <div className="form-group">
              <span>E-mail</span>
              <input type="email" name="email"
              value={data && data.email} className="form-control" onChange={evt => handleChange(evt)}/>
            </div>

            <div className="form-group">
              <span>Senha</span>
                <div className="input-group">
                    <input
                        type={visible === true ? "text":"password"}
                        className={"form-control " + fieldStatus}
                        name="password"
                        value={data && data.password}
                        placeholder="Senha"
                        onChange={evt => handlePasswordChange(evt)}/>
                    <div class="input-group-append">
                        <span class="input-group-text" onClick={() => setVisible(!visible)}>
                            <i className={
                            visible === true
                                ? "far fa-fw fa-eye"
                                : "far fa-fw fa-eye-slash"
                            }/>
                        </span>
                    </div>
                </div>
            </div>
            <small className="">
                * A senha precisa de ao menos 1 caracter especial, letra maiúscula,
                minúscula e número
            </small>
            <div className="form-group">
              <span>Confirme a senha</span>
                <div className="input-group">
                    <input
                        type={visible === true ? "text":"password"}
                        className={"form-control " + fieldStatus}
                        name="passwordConfirm"
                        value={data && data.passwordConfirm}
                        placeholder="Senha"
                        onChange={evt => handlePasswordChange(evt)}/>
                    <div class="input-group-append">
                        <span class="input-group-text" onClick={() => setVisible(!visible)}>
                            <i className={
                            visible === true
                                ? "far fa-fw fa-eye"
                                : "far fa-fw fa-eye-slash"
                            }/>
                        </span>
                    </div>
                </div>
            </div>

            {update == undefined ? 
            <div className="form-group">
                <div className="custom-control custom-checkbox custom-control-primary">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={"check"}
                    name={"terms"}
                    checked={data && data.terms}
                    onChange={(evt) =>
                    handleChange({target: {name: "terms", value: evt.target.checked}}, FieldTypeObj.Bool)
                    }
                />
                <label
                    className="custom-control-label"
                    for={"check"}
                >
                    Li e concordo com os 
                    <a href={`#/terms`} target="_blank" className="text-warning">
                    Termos de uso, Política de Privacidade, 
                    </a>{" "}
                    ciente de que meus dados pessoais serão utilizados exclusivamente
                     para os fins dispostos nestes documentos.
                </label>
                </div>
            </div>
            :""}
            
            {update == undefined ? 
                <button className="btn btn-block btn-hero-lg btn-hero-warning" onClick={()=> nextStep()}>
                    Avançar
                </button>
            :""}
            
            {update == undefined ? 
                <button className="btn btn-block btn-hero-lg btn-hero-white text-warning" onClick={()=> prevStep()}>
                    Voltar
                </button>
            :""}
        </div>
    )
}