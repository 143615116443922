import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import SignInForm from "./SignInForm";
import Logo from "@/assets/img/logo.png";
import Storage from "@/app/core/util/Storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountController from "@/app/core/base/controllers/UsersController";
import { HandleAuthErrors } from "@/app/core/util/helpers/ErrorHelper";
import GenericHeader from "@/app/core/layouts/main/components/GenericHeader";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { child, get, getDatabase, ref } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";


export default function SignIn(props) {
  const [authenticate, setAuthenticate] = useState(false);
  const [loading, setLoading] = useState(false);
  const accountCtrl = AccountController();
  const auth = getAuth();
  const db = getDatabase(firebaseApp);

  async function onSubmit(form) {
    signInWithEmailAndPassword(auth, form.values.email, form.values.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("usuario",user)
        get(child(ref(db), `users/${user.uid}`)).then((snapshot) => {
          let teams = [];
          let fields = [];
          if (snapshot.exists()) {
              const notYet = snapshot.val();
              Storage.setUserData({...notYet, id: user.uid});
              setTimeout(()=>{
                window.location = "#/";
              },500)
          }
      });
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });

      setLoading(true);
  }

  function goRegister(){
    window.location = `#/register`
  }

  if (authenticate) return <Redirect to="/app" />;
  return (
    <div id="page-container">
      <main id="main-container">
        <div
          className="bg-image"
          style={{
            backgroundImage: "url('assets/media/photos/photo11@2x.jpg')",
          }}
        >
          <div className="row no-gutters bg-black-50">
            <div className="hero-static col-md-6 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <div className="mb-3 text-center">
                  <p className="text-uppercase font-w700 font-size-sm text-warning">
                    Olá, seja bem-vindo!
                  </p>
                </div>

                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-8 col-xl-6">
                    <SignInForm
                      submit={(form) => onSubmit(form)}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
              <div className="p-3">
                <p className="display-4 font-w700 text-white mb-3">
                  {/* Colocar um texto legal */}
                </p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Copyright <b>AppMotos</b> &copy;{" "}
                  <span className="js-year-copy">
                    {new Date().getFullYear()}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
}
