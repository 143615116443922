import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleAuthErrors, HandleErrors } from "../../util/helpers/ErrorHelper";
import { getDatabase, ref, set } from "firebase/database";
import { firebaseApp } from "../ControllerBase";
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged } from "firebase/auth";

export default function AccountController() {
  const db = getDatabase(firebaseApp);

  async function Register(values, response, error) {
    const auth = getAuth();
    let data = {};
    await createUserWithEmailAndPassword(auth, values.email, values.password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("Conta");
        await set(ref(db, `users/${user.uid}`), {
            ...values
        });
        data = {
          id: user.uid,
          ...values,
        }
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage);
        // ..
      });

      return data;

    // await BaseService(
    //   {
    //     method: "POST",
    //     url: "account/register",
    //     data: values,
    //   },
    //   (res) => {
    //     response(res);
    //   },
    //   (err) =>
    //     error === undefined ? toast.error(HandleAuthErrors(err)) : error(err)
    // );
  }

  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "account/update",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err)
    );
  }

  async function CompanyUsers(response, error) {
    await BaseService(
      {
        method: "GET",
        url: "account/company",
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err)
    );
  }

  async function RestartPassword(userId, data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `account/users/${userId}/password/update`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err)
    );
  }

  async function AdministratorRestartPassword(userId, data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `account/administrator/users/${userId}/password/update`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err)
    );
  }

  async function ConsultEmail(email, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `account/consult?email=${email}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err)
    );
  }

  async function Login(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "account/login",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err)
    );
  }

  async function AvatarUpload(formData, userId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `account/users/${userId}/upload-avatar`,
        data: formData,
        contentType: "multipart/form-data",
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err)
    );
  }

  function AdministratorForgotPassword(email, response) {
    BaseService(
      {
        method: "POST",
        url: `account/administrator/password/forgot/${email}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        console.log("Erro", err);
        console.log("Erro", err.response.data);
        toast.error(err.response.data);
      }
    );
  }

  return {
    Register,
    Update,
    ConsultEmail,
    Login,
    AvatarUpload,
    CompanyUsers,
    RestartPassword,
    AdministratorRestartPassword,
    AdministratorForgotPassword
  };
}
