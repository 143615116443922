import React from "react";
import RecoverComponent from "./main";
import Layout from "@/app/core/layouts/main";
import { renderRoutes } from "react-router-config";

const EmptyRouter = (props) => <Layout {...props}>{renderRoutes(props.route.routes, props)}</Layout>;

const ContactRouter = () => ({
  path: "/contact",
  exact: true,
  component: EmptyRouter,
  routes: [
    {
      path: "/contact",
      component: RecoverComponent,
    },
  ],
});

export default ContactRouter();
