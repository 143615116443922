import React, { useEffect, useState } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import Grid from "./Grid";
import BigSearch from "./BigSearch";
import { firebaseApp } from "@/app/core/base/ControllerBase";
import { ContentType } from "@/app/core/util/helpers/ContentHelper";
import { child, get, getDatabase, ref } from "firebase/database";

export default function MainDashboard(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [query, setQuery] = useState("");
  const [otherNews, setOtherNews] = useState([]);
  const db = getDatabase(firebaseApp);


  useEffect(()=>{
    loadPresentation();
},[]);

useEffect(()=>{
    search(query);
},[query]);

function loadPresentation() {
    let param = props.location.search;
    let term = "";
    let item = param.split("?")[1].split("&"),
    returnBool = false;

    for (var i = 0; i < item.length; i++) {
        let parr = item[i].split("=");
        if (parr[0] == "query") {
            let itens = decodeURIComponent(parr[1]);
            term = itens
            returnBool = true;
        }
    }

    if (returnBool === true) {
        setQuery(term);
    }
    loadOtherPosts();
  }

  function search(value){
    const old = otherNews;
    const result = [];
    const params = ["description", "title", "url"]
    for(let s in old){
        const item = old[s];

        const description = item.description.toLowerCase();
        const title = item.title.toLowerCase();
        if (description.includes(value.toLowerCase()) || title.includes(value.toLowerCase())) {
            result.push(item);
        }else{
            for(let y in item.sections){
                const base = item.sections[y];
                const nDescription = base.description != undefined ? base.description.toLowerCase():"";
                const nUrl = base.url != undefined ? base.url.toLowerCase():"";
                if (nDescription.includes(value.toLowerCase()) || nUrl.includes(value.toLowerCase())) {
                    result.push(item);
                }
            }
        }
    }
    setData(result);
  }

  function loadOtherPosts(){
    get(child(ref(db), `news`)).then((snapshot) => {
        let sportsData = [];
        let res = [];
        if (snapshot.exists()) {
            const notYet = snapshot.val();
            for (let s in notYet) {
                sportsData.push({
                    ...notYet[s],
                    id: s,
                })
            }
            let count = 0;
            for(let s in sportsData){
                const i = sportsData[s];
                for(let c in i){
                    if(count <= 2){
                        count++;
                        res.push({...i[c], sportId: i.id, id: c});
                    }
                }
            }
            console.log(res);
            setOtherNews(res);
            search(query)
          }
      });
  }

    function openPost(id){
        window.location = `#/post?postId=${id}/${id}`
    }

  return (
    <>
      <Loading loading={loading} />
      <BigSearch value={query} search={item => setQuery(item)} />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          {loading === true ? (
              <div className="block-content text-center">
                  <i className={"fa fa-circle-notch fa-spin"}></i>
              </div>
            ):""}
          
          {data && data.length == 0 ? 
                <div className="col-md-12">
                    <div className="text-center">
                        Não encontramos o que está procurando
                    </div>
                </div>
            :""}
            {data && data.map(item => (
                <div class="block block-rounded block-fx-pop col-md-4" onClick={()=> openPost(item.id)}>
                    <div class="block-content block-content-full bg-image p-7" style={{backgroundImage: `url('${item && item.image && item.image.url}')`, height: 340}}>
                    </div>
                    <div class="block-content block-content-full block-content-sm bg-body-light">
                        <div class="font-size-sm text-muted">{item && item.tags && item.tags.map(tag => <span className="text-warning mr-2">{tag} |</span>)}</div>
                        <div class="font-w600">{item.title}</div>
                    </div>
                    <div class="block-content block-content-full">
                        <p>{item && item.description}</p>
                    </div>
                </div>
            ))}
        </div>
      </div>
    </>
  );
}
