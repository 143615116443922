import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function CustomModalComponent(props) {
  function sizeModal() {
    if (props.size !== undefined && props.size !== null) {
      return props.size;
    } else {
      return "lg";
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size={sizeModal()}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header className="bg bg-warning" closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter text-white"
          style={{ color: "white" }}
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`block  block-mode-loading-dark block-mode-loading-refresh ${
          props.isLoading ? "block-mode-loading" : ""
        }`}
      >
        {props.children}
      </Modal.Body>
    </Modal>
  );
}
