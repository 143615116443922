import { TranslateDay } from "@/app/core/util/DateTranslater";
import React, { useEffect, useState } from "react";
import handleChange from "@/app/core/util/helpers/HandleChange";
import { toast } from "react-toastify";
import DocInput from "@/app/core/layouts/main-components/files/DocInput";
import PictureItem from "./PictureItem";
import { getDownloadURL, getStorage, ref as refStor, uploadBytesResumable } from "firebase/storage";
import { child, get, getDatabase, push, ref } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";

export default function ArenaPictureStep({data, nextStep, prevStep}) {
    const db = getDatabase(firebaseApp);
    const [infos, setInfos] = useState({});
    const [pictures, setPicutres] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        loadPictures();
    },[])

    function loadPictures(){
        get(child(ref(db), `teams/${data.id}/images`)).then((snapshot) => {
            let pics = [];
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    pics.push({
                        ...notYet[s],
                        id: s,
                    })
                }
                console.log("fotos", pics)
                setPicutres(pics);
            }
        });
    }

    function uploadFile(files){
        const storage = getStorage();
        const reference = refStor(storage, `${data.id}`);

        // 'file' comes from the Blob or File API
        const uploadTask = uploadBytesResumable(reference, files[0]);
        uploadTask.on('state_changed',
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                console.log('Upload is paused');
                break;
                case 'running':
                console.log('Upload is running');
                break;
            }
            },
            (error) => {
            // Handle unsuccessful uploads
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    push(ref(db, `teams/${data.id}/images`), {
                        url: downloadURL,
                        name: "Sem nome",
                    });
                    loadPictures();
                });
            }
        )
    }

    return (
        <div>
            <p className="text-uppercase font-w700 font-size-sm text-center">
                Para conhecerem melhor as instações, envie 4 imagens do local.
                <small>
                    Ex.: Quadra/campo, estacionamento, vestiário, churrasqueira, etc.
                </small>
            </p>
            <div className="text-center">
                <DocInput
                    name={"Adicionar Foto"}
                    multiple={true}
                    loading={loading}
                    uploadFile={evt => uploadFile(evt)}
                />
            </div>
            
            {pictures && pictures.map((item, index) => (
                <PictureItem picture={item} teamId={data.id} reload={()=> loadPictures()}/>
            ))}

            <button className="btn btn-block btn-hero-lg btn-hero-warning mt-3" onClick={()=> nextStep()}>
                Avançar
            </button>
            <button className="btn btn-block btn-hero-lg btn-hero-white text-warning" onClick={()=> prevStep()}>
                Voltar
            </button>
        </div>
    )
}