import TeamModal from "@/app/core/layouts/main-components/teams/TeamModal";
import { TranslateDay } from "@/app/core/util/DateTranslater";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import handleChange from "@/app/core/util/helpers/HandleChange";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { child, get, getDatabase, ref } from "firebase/database";
import { firebaseApp } from "@/app/core/base/ControllerBase";

export default function Post(props){
    const [query, setQuery] = useState("");
    const [data, setData] = useState({});
    const [preLoad, setPreload] = useState({});
    const [selected, setSelected] = useState({});
    const [sports, setSports] = useState([]);
    const [fieldType, setFieldType] = useState([]);
    const [ageGroup, setAgeGroup] = useState([]);
    const [results, setResults] = useState([]);
    const [showFilter, setShowFilter] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const db = getDatabase(firebaseApp);

    useEffect(()=>{
        loadPresentation()
        loadSports()
    },[]);
    
    function loadNearTeams(fieldTypes, ageGroups){

        get(child(ref(db), `teams`)).then((snapshot) => {
            let teams = [];
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    const res = fieldTypes.filter(x => x.id == notYet[s].fieldTypeId);
                    const ages = ageGroups.filter(x => x.id == notYet[s].ageGroupId);
                    let obj = {
                        ...notYet[s],
                        id: s,
                    };

                    if(res.length > 0){
                        obj.fieldType = res[0];
                    }
                    if(ages.length > 0){
                        obj.ageGroup = ages[0];
                    }
                    teams.push(obj)
                }
                console.log("query",teams);
                setPreload(teams);
            }
        });
    }
    
    function loadSports(){
        get(child(ref(db), `sports`)).then((snapshot) => {
            let sportList = [];
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    sportList.push({
                        ...notYet[s],
                        id: s,
                    })
                }
                setSports(sportList);
            }
        });
    }

    function loadPresentation() {
        get(child(ref(db), `fieldType`)).then((snapshot) => {
            let teams = [];
            let fields = [];
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    teams.push({
                        ...notYet[s],
                        id: s,
                    })
                }

                for(let s in teams){
                    const i = teams[s];
                    let images = [];
                    for(let c in i){
                        fields.push({...i[c], sportId: i.id, id: c});
                    }
                }
                setFieldType(fields);
                loadAgeGroup(fields);
            }
        });
    }
    function loadAgeGroup(fieldTypes) {
        get(child(ref(db), `ageGroup`)).then((snapshot) => {
            let agegroups = [];
            if (snapshot.exists()) {
                const notYet = snapshot.val();
                for (let s in notYet) {
                    agegroups.push({
                        ...notYet[s],
                        id: s,
                    })
                }
                setAgeGroup(agegroups);
                
                loadNearTeams(fieldTypes, agegroups)
            }
        });
    }

    async function search() {
        const base = preLoad;
        let res = [];
        let pre = [];

        if(data.sportId != undefined && data.sportId != ""){
            for(let s in base){
                const item = base[s];
                if(item.sportId === data.sportId){
                    pre.push(item);
                }
            }
        }
        if(data.city != undefined && data.city != ""){
            const old = pre;
            pre = [];
            for(let s in old){
                const item = old[s];
                if(item.city.trim().toLowerCase() === data.city.trim().toLowerCase()){
                    pre.push(item);
                }
            }
        }
        if(data.date != undefined && data.date != ""){
            const old = pre;
            var dateParts = data.date.split("-");
            // month is 0-based, that's why we need dataParts[1] - 1
            var dateObject = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
            const getWeekDay = dateObject.getDay();
            console.log("data", dateObject);

            pre = [];
            for(let s in old){
                const item = old[s];
                for(let i in item.schedule){
                    const baseSchedule = item.schedule[i];
                    let startTime = baseSchedule.startTime.split(":");
                    let endTime = baseSchedule.endTime.split(":");
                    let querystartTime = data.startTime.split(":");
                    let queryendTime = data.endTime.split(":");
                    if(baseSchedule.weekDay == getWeekDay &&
                        startTime[0] == querystartTime[0] &&
                        endTime[0] == queryendTime[0]){
                            pre.push(item);
                    }
                }
            }
        }

        setShowFilter(false);
    }

    function processQuery(value){
        let teams_to_show = [];
        setQuery(value);
        if (value.length > 0) {
            setResults([]);
            teams_to_show = autocomplete(value);
            setResults(teams_to_show);
        } else {
            teams_to_show = [];
            setResults([]);
        }
    }

    function autocomplete(value) {
        const inputValue = value.trim().toLowerCase();
        let itens = [];
        for (let s in preLoad) {
            itens.push(preLoad[s]);
        }

        let newResults = [];

        for (let s in itens) {
            const item = itens[s];
            if(item != undefined){
                const name = item.name != undefined ? item.name.toLowerCase():"";
                const color = item.color != undefined ? item.color.toLowerCase():"";
                const cep = item.cep != undefined ? item.cep.toLowerCase():"";
                const address = item.address != undefined ? item.address.toLowerCase():"";
    
                if (name.includes(inputValue)) {
                    newResults.push(item);
                }else if (color.includes(inputValue)) {
                    newResults.push(item);
                }else if (cep.includes(inputValue)) {
                    newResults.push(item);
                }else if (address.includes(inputValue)) {
                    newResults.push(item);
                }
            }
        }

        return newResults;
    }

    function onDataChange(evt, type) {
        setData(handleChange(data, evt, type));
        console.log("estrutura de pesquisa", data);
    }

    function toggleModal(item){
        setSelected(item);
        setShowModal(true)
    }

    return (
        <div className="row">
            <div className="col-md-6">
                <div className="block block-fx-pop block-rounded">
                    <div className="block-content">
                        <div class="form-group">
                            <span>Digite o nome do time, campo, quadra e etc.</span>
                            <div class="input-group">
                                <input type="email" class="form-control" name="query" placeholder="Pesquisar..." value={query} onChange={evt => processQuery(evt.target.value)}/>
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {showFilter ? 
                        <div>
                            <div className="row">
                                <div className="col-5"><hr/></div>
                                <div className="col-2">OU</div>
                                <div className="col-5"><hr/></div>
                            </div>
                            <div className="form-group">
                                <span>Esporte</span>
                                <Typeahead
                                    options={sports}
                                    labelKey={(option) => `${option.name}`}
                                    onSearch={(q) => setQuery(q)}
                                    id="advanced-filter-state"
                                    placeholder="Selecionar Esporte"
                                    onChange={(selected) => {
                                        if (selected[0] !== undefined || selected !== null) {
                                            onDataChange({target: {value: selected[0].id, name: "sportId"}});
                                        } 
                                    }}
                                />
                            </div>
                            <div class="form-group">
                                <span>Cidade</span>
                                <input type="email" class="form-control" name="address" placeholder="Pesquisar..." onChange={evt => onDataChange(evt)}/>
                            </div>
                            <div className="form-group">
                                <span>Data</span>
                                <input type="date" className="form-control" name="date" value={data && data.date} onChange={evt => onDataChange(evt)}/>
                            </div>
                            <div className="row">
                                <div className="col-6 form-group">
                                    <span>Hora Início</span>
                                    <input className="form-control" type="time" name="startTime" value={data && data.startTime} onChange={evt => onDataChange(evt)}/>
                                </div>
                                <div className="col-6 form-group">
                                    <span>Hora Fim</span>
                                    <input className="form-control" type="time" name="endTime" value={data && data.endTime} onChange={evt => onDataChange(evt)}/>
                                </div>
                            </div>
                            <button className="btn btn-block btn-hero-lg btn-hero-warning mb-2" onClick={()=> search()}>
                                Localizar
                            </button>
                        </div>
                        :
                            <button className="btn btn-block btn-outline-warning mb-2" onClick={()=> setShowFilter(true)}>
                                Nova consulta
                            </button>
                        }

                        
                    </div>
                </div>
            </div>    
            <div className="col-md-6">
                <div className="block block-fx-pop block-rounded">
                    <div className="block-header">
                        {results && results.length === 0 ? 
                            <div className="block-title">{results.length} resultados</div>
                            :
                            <div className="block-title">Olha o que encontramos:</div>
                        }
                    </div>
                    <div className="block-content">
                        {results && results.map(item => (
                            <div class="block block-bordered bg-gray-light block-rounded" onClick={()=> toggleModal(item)}>
                                <div class="block-header row">
                                    <h3 class="col-3 text-center">
                                        <img src={item.logo && item.logo.url} className="img-avatar img-avatar-thumb img-avatar-rounded border-gray border-2x border"/>
                                    </h3>
                                    <div class="col-9 text-left">
                                        <h4 className="mb-0 font-w700">{item.name}</h4>
                                        <p>
                                            {item && item.arenaName}<br/>
                                            {item.city} / {item.state}
                                        </p>
                                    </div>
                                </div>
                                <div class="block-content block-bordered text-center block-content-full block-content-sm bg-gray-dark text-white font-size-sm">
                                    {item.schedule && item.schedule.map(date => <span className="mr-2">{TranslateDay(date.weekDay)} - {date.startTime} às {date.endTime}</span>)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>      
            </div>
            <TeamModal
                show={showModal}
                onHide={() => setSelected({})|setShowModal(false)}
                selected={selected}
                teams={preLoad}
            />    
        </div>
    )
}