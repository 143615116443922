import React, { useState } from "react";
import { toast } from "react-toastify";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import CopyToClipboard from "react-copy-to-clipboard";

export default function InviteStep({teamId, endRegister, prevStep, data}) {
    const [infos, setInfos] = useState({});

    
  function notifyCopy() {
    toast.info("Copiado para a área de transferência");
  }

    return (
        <div className="text-center">
            <p className="text-uppercase font-w700 font-size-sm text-center">
                Pronto! agora envie o convite do seu time para seus jogadores realizar o cadastro.
            </p>
            <br/>
            <small>
                É muito simples, copie o código link esclusivo do time e envie da forma que você acha melhor ou compartilhe e escolha as formas de envio.
            </small>

            <CopyToClipboard
                text={`${GetBaseUrl()}#/register?teamId=${data.id}`}
                >
                <button
                    type="button"
                    className="btn btn-sm btn-light mr-1 mb-3"
                    title="copiar"
                    onClick={() => notifyCopy()}
                >
                    <i className="far fa-copy mr-1" /> Copiar link do convite
                </button>
            </CopyToClipboard>

            <button className="btn btn-block btn-hero-lg btn-hero-warning mt-3" onClick={()=> endRegister()}>
                Finalizar cadastro
            </button>
            <button className="btn btn-block btn-hero-lg btn-hero-white text-warning" onClick={()=> prevStep()}>
                Voltar
            </button>
        </div>
    )
}