import { DayOfTheWeek, TranslateDay } from "@/app/core/util/DateTranslater";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import React, { useState } from "react";
import handleChange from "@/app/core/util/helpers/HandleChange";
import { toast } from "react-toastify";

export default function TeamDataStep({data, nextStep, prevStep, addSchedule, removeSchedule}) {
    const [infos, setInfos] = useState({});

    function handleTimeChange(evt, type){
        setInfos(handleChange(infos, evt, type));
        console.log("horarios",infos);
    }

    function addTime(){
        console.log("horarios",infos);
        if(infos.weekDay == undefined){
            toast.error("Preencha todos os campos para salvar");
        }
        else if(infos.startTime == undefined || infos.startTime == ""){
            toast.error("Preencha todos os campos para salvar");
        }
        else if(infos.endTime == undefined || infos.endTime == ""){
            toast.error("Preencha todos os campos para salvar");
        }else{
            addSchedule(infos);
        }
    }

    return (
        <div>
            <p className="text-uppercase font-w700 font-size-sm text-center">
                Nos informe o dia da semana e horário que seu time usa o campo/quadra.
            </p>
            <div className="form-group">
                <span>Dia da semana</span>
                <select className="form-control" name="weekDay" value={infos.weekDay} onChange={evt => handleTimeChange(evt, FieldTypeObj.Number)}>
                    <option>Selecione</option>
                    {DayOfTheWeek.map(item => (
                        <option value={item.value}>{item.name}</option>
                    ))}
                </select>
            </div>
            <div className="row">
                <div className="col-6 form-group">
                    <span>Hora Início</span>
                    <input className="form-control" type="time" name="startTime" value={infos.startTime} onChange={evt => handleTimeChange(evt)}/>
                </div>
                <div className="col-6 form-group">
                    <span>Hora Fim</span>
                    <input className="form-control" type="time" name="endTime" value={infos.endTime} onChange={evt => handleTimeChange(evt)}/>
                </div>
            </div>

            <button className="btn btn-block btn-hero-lg btn-hero-white text-warning" onClick={()=> addTime()}>
                Adicionar
            </button>
            
            {data && data.map((item, index) => (
                <div className="block block-rounded block-bordered">
                    <div className="block-header">
                        <div className="block-title">
                            {TranslateDay(item.weekDay)} - das {item.startTime} às {item.endTime}
                        </div>
                        <div className="block-tools">
                            <button className="btn btn-dual text-danger" onClick={()=> removeSchedule(item, index)}>
                                <i className="si si-close"/>
                            </button>
                        </div>
                    </div>
                </div>
            ))}

            <button className="btn btn-block btn-hero-lg btn-hero-warning mt-3" onClick={()=> nextStep()}>
                Avançar
            </button>
            <button className="btn btn-block btn-hero-lg btn-hero-white text-warning" onClick={()=> prevStep()}>
                Voltar
            </button>
        </div>
    )
}